import RomaTour from "./RomaTour";
import { enumIcons } from "~/utils/builder/enums";

const inputs = [
  {
    name: "sectionTitle",
    type: "string",
  },
  {
    name: "tours",
    type: "list",
    onChange: (options: any, parent: any) => {
      if (options.get("tours").length > 3) {
        options.set("tours", options.get("tours").slice(0, 3));
        alert("maximum items is 3");
      }
    },
    subFields: [
      {
        name: "title",
        type: "string",
      },
      {
        name: "icon",
        type: "string",
        enum: enumIcons,
      },
      {
        name: "bestValue",
        type: "boolean",
        friendlyName: "Best Value?",
        helperText:
          "Only select one, this will place additional text under the title automatically",
      },
      {
        name: "price",
        type: "string",
      },
      {
        name: "duration",
        type: "string",
      },
      {
        name: "features",
        type: "list",
        subFields: [
          {
            name: "title",
            type: "string",
            helperText: "If you need items on new lines, separate with commas",
          },
        ],
      },
      {
        name: "buttonText",
        friendlyName: "Button Text",
        type: "string",
      },
      {
        name: "buttonStyle",
        friendlyName: "Button Style",
        type: "string",
        enum: ["Outline", "Solid"],
        defaultValue: "Outline",
        helperText:
          "Note: Button may change size while previewing, reload after publishing and it should be back to full width",
      },
      {
        name: "buttonHref",
        friendlyName: "Button Link",
        type: "string",
      },
    ],
    defaultValue: [
      {
        title: "Roma Tour",
        icon: "Tour 1 Star",
        bestValue: false,
        price: "Free",
        duration: "60 minutes",
        features: [
          { title: "Headquarters tour" },
          { title: "Q&A Session:,A Department Leader of Choice" },
        ],
        buttonText: "Register",
        buttonStyle: "Outline",
        // buttonHref: "#",
      },
      {
        title: "WOW Tour",
        icon: "Tour 3 Star",
        bestValue: true,
        price: "$95 per person ",
        duration: "120 minutes",
        features: [
          { title: "Headquarters tour" },
          {
            title:
              "Q&A Session:,Roma's Chief of WOW Tony Gareri,A Department Leader of Choice",
          },
          { title: "A customized WOW Package" },
          { title: "Culture Presenation" },
        ],
        buttonText: "Register",
        buttonStyle: "Solid",
        // buttonHref: "#",
      },
      {
        title: "Inspired Tour",
        icon: "Tour 2 Star",
        bestValue: false,
        price: "$50 per person ",
        duration: "90 minutes",
        features: [
          { title: "Headquarters tour" },
          {
            title:
              "Q&A Session:,Roma's Chief of WOW Tony Gareri,A Department Leader of Choice",
          },
          { title: "A customized WOW Package" },
        ],
        buttonText: "Register",
        buttonStyle: "Solid",
        // buttonHref: "#",
      },
    ],
  },
  {
    name: "sectionId",
    friendlyName: "Section ID",
    helperText:
      "Use a unique ID with no spaces if this specific section of the page will be linked to (anchor link)",
    type: "string",
  },
] as const;

export type RomaTourFields = BuilderFields<GenerateItems<typeof inputs>>;

const RomaTourBlock = {
  component: RomaTour,
  name: "RomaTour",
  friendlyName: "Roma Tour",
  section: "Content Components",
  inputs,
};

export default RomaTourBlock;
