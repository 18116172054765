import SupportGrid from "./SupportGrid";
import { enumIcons } from "~/utils/builder/enums";

const inputs = [
  {
    name: "sectionTitle",
    type: "string",
    friendlyName: "Section Title",
    helperText: "Optional",
  },
  {
    name: "data",
    friendlyName: "Links",
    type: "list",
    subFields: [
      {
        name: "title",
        type: "string",
      },
      {
        name: "href",
        friendlyName: "Url",
        type: "url",
        defaultValue: "/",
      },
      {
        name: "icon",
        type: "string",
        enum: enumIcons,
      },
    ],
    defaultValue: [
      {
        title: "Ordering",
        href: "#",
        icon: "Ordering",
      },
      {
        title: "Shipping",
        href: "#",
        icon: "Shipping",
      },
      {
        title: "Returns",
        href: "#",
        icon: "Returns",
      },
      {
        title: "Billing",
        href: "#",
        icon: "Billing",
      },
      {
        title: "Your Account",
        href: "#",
        icon: "User",
      },
      {
        title: "Products",
        href: "#",
        icon: "Catalogue",
      },
    ],
  },
];

export type SupportGridFields = BuilderFields<GenerateItems<typeof inputs>>;

const SupportGridBlock = {
  component: SupportGrid,
  name: "SupportGrid",
  friendlyName: "Support Grid",
  inputs,
};

export default SupportGridBlock;
