import ShortQuote from "./ShortQuote";
import { enumContentWidth } from "~/utils/builder/enums";

const inputs = [
  {
    name: "bodyText",
    friendlyName: "Short Quote",
    type: "longText",
    required: true,
  },
  {
    name: "quotee",
    friendlyName: "Quotee Name",
    helperText: "eg. Tony Gareri, CEO",
    type: "string",
  },
  {
    name: "isQuotationMark",
    friendlyName: "Add Quotation Marks?",
    type: "boolean",
    defaultValue: true,
  },
  {
    name: "contentWidth",
    friendlyName: "Text Content Width",
    type: "string",
    enum: enumContentWidth,
    defaultValue:  "lg-512px",
    helperText: "Use if the content needs to be constrained"
  },
] as const;

export type ShortQuoteFields = BuilderFields<GenerateItems<typeof inputs>>;

const ShortQuoteBlock = {
  component: ShortQuote,
  name: "ShortQuote",
  friendlyName: "Short Quote",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F93355e15dd994c9eae34f37980743454",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F0537005dfd1a4f3296ef4fa11e646a28",
  inputs,
};

export default ShortQuoteBlock;
