import { Component, Show, mergeProps } from "solid-js";
import { LongQuoteFields } from "./LongQuote.block";
import { Img } from "~/components/image";

const LongQuote: Component<LongQuoteFields> = (inProps) => {
  const props = mergeProps(
    {
      quotationContent:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex autem voluptatem iste minima veniam dolor animi nisi magni cumque perferendis est sapiente eum deserunt ipsa in totam quo nihil vel blanditiis tenetur neque iure, illum laudantium? Vel eos vero cumque, iste blanditiis velit impedit veritatis, deleniti error beatae recusandae delectus.",
    },
    inProps
  );
  return (
    <section
      class="boundary-outer"
      classList={{ "boundary-outer": !props.fullWidth }}
    >
      <div
        class="bg-roma-grey flex flex-col gap-3"
        classList={{
          "justify-center items-center p-20 text-center": !props.image,
          "md:flex-row-reverse": props.layout === "Left",
          "md:flex-row": props.layout !== "Left",
        }}
      >
        <div
          class={`${
            props.image ? "basis-1/2 boundary-inner py-10" : ""
          } flex justify-center items-center md:px-10 lg:px-20`}
        >
          <blockquote class="leading-8">
            {props.isQuotationMark ? "“" : ""}
            {props.quotationContent}
            {props.isQuotationMark ? "”" : ""}
            <p class="font-bold pt-2">- {props.quotee}</p>
          </blockquote>
        </div>
        <Show when={props.image}>
          <div class="self-center md:self-end basis-1/2 flex sm:flex justify-center sm:pt-10">
            <Img
              src={props.image as string}
              alt={props.altText ?? ""}
              width={500}
              height={500}
              class="grayscale max-h-[250px] sm:max-h-[500px]"
            />
          </div>
        </Show>
      </div>
    </section>
  );
};

export default LongQuote;
