import {
  Component,
  mergeProps,
  For,
  createSignal,
  Switch,
  Match,
} from "solid-js";
import { FormFields } from "./Form.block";
import { TextFieldInput } from "./inputs";
import { createForm } from "@felte/solid";
import * as z from "zod";
import { validator } from "@felte/validator-zod";

const Form: Component<FormFields> = (inProps) => {
  const [submitted, setSubmitted] = createSignal<string | undefined>();
  const props = mergeProps(
    {
      header: "Heading Text",
      bodyContent:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae possimus et natus aut asperiores ducimus distinctio, alias unde debitis excepturi officiis laudantium harum ea commodi corporis veniam. Adipisci, minus officia?",
    },
    inProps
  );

  type ValidationType = "Phone" | "Email" | "None";

  type FormInput = {
    label: string;
    validationType: ValidationType;
    maxLength?: number;
    isRequired: boolean;
  };

  type FormSchema = {
    [key: string]: z.ZodString;
  };

  const createFormSchema = (fields: FormInput[]): z.ZodObject<FormSchema> => {
    const schemaFields: FormSchema = {};
    for (const field of fields) {
      let fieldSchema = z.string();
      if (field.validationType === "Email") {
        fieldSchema = fieldSchema.email();
      }
      if (field.isRequired) {
        fieldSchema = fieldSchema.nonempty({
          message: " This field cannot be empty ",
        });
      }
      if (field.maxLength) {
        fieldSchema = fieldSchema.max(field.maxLength);
      }
      schemaFields[field.label] = fieldSchema;
    }
    return z.object(schemaFields);
  };

  const schema = createFormSchema(props.input);

  const {
    form,
    isSubmitting,
    reset: resetForm,
    errors,
  } = createForm({
    extend: validator({ schema }),
    onSubmit: async (values) => {
      setSubmitted(undefined);
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/partners/specialist`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        if (response.ok) {
          setSubmitted("success");
          resetForm();
        } else {
          const errorText = await response.text();
          throw new Error(
            `Request failed with status code ${response.status}. Error: ${errorText}`
          );
        }
      } catch (err) {
        console.log(err);
        setSubmitted("error");
      }
    },
  });

  form;

  return (
    <section
      id={props.id ? props.id.trim().replaceAll(" ", "") : undefined}
      class="bg-roma-grey flex justify-center items-center py-20"
    >
      <div class="w-full md:max-w-3xl flex flex-col px-10">
        <div class="flex flex-col items-center text-center pb-10 gap-5">
          <h3 class="text-3xl font-bold">{props.header}</h3>
          <p>{props.bodyContent}</p>
        </div>
        <form use:form class="flex flex-col gap-4">
          <For each={props.input}>
            {(item) => (
              <div class="flex flex-col gap-2">
                <label for={item.label} class="font-bold text-sm">
                  {item.fieldName}
                  {item.isRequired ? "*" : ""}
                </label>
                <TextFieldInput
                  type={item.type.toLowerCase()}
                  autoResize={item.type === "TextArea" ? true : undefined}
                  name={item.label}
                  placeholder={item.placeholder}
                  required={item.isRequired}
                  class="rounded-sm border-gray-400 border p-2"
                  error={errors()[item.label]}
                  validationState={errors()[item.label] ? "invalid" : "valid"}
                  disabled={isSubmitting()}
                />
                <p class="text-xs text-gray-400">{item.instruction}</p>
              </div>
            )}
          </For>
          <button
            type="submit"
            class="bg-black text-white text-lg font-bold py-3 my-2 rounded-full border-2 border-black hover:bg-transparent hover:text-black transition"
          >
            {props.buttonText}
          </button>
        </form>
        <Switch>
          <Match when={submitted() === "success"}>
            <div class="mt-8 p-4 border bg-faint-blue flex flex-col items-center justify-center">
              <p class="font-bold text-center">
                Thanks for your interest! Our team will be in touch within two
                business days
              </p>
            </div>
          </Match>
          <Match when={submitted() === "error"}>
            <div class="mt-8 p-4 border bg-faint-blue flex flex-col items-center justify-center">
              <p>An error occured while processing your submission.</p>
              <p>
                Please try again, or contact support at{" "}
                <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                  1-800-263-2322
                </a>
              </p>
            </div>
          </Match>
        </Switch>
      </div>
    </section>
  );
};

export default Form;
