import { Component, Show, mergeProps } from "solid-js";
import { ShortQuoteFields } from "./ShortQuote.block";
import { contentWidth } from "~/utils/builder/enums";

const ShortQuote: Component<ShortQuoteFields> = (inProps) => {
  const props = mergeProps(
    {
      bodyText: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    },
    inProps
  );
  return (
    <div class="flex justify-center items-center text-center py-24 px-5">
      <blockquote class="text-3xl" classList={{[contentWidth[props.contentWidth as string]]: !!props.contentWidth}}>
        <p class="font-bold">
          {props.isQuotationMark ? "“" : ""}
          {props.bodyText}
          {props.isQuotationMark ? "”" : ""}
        </p>
        <Show when={props.quotee}>
          <cite class="text-base pt-2 not-italic font-medium">&mdash; {props.quotee}</cite>
        </Show>
      </blockquote>
    </div>
  );
};

export default ShortQuote;
