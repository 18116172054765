import { Component, Show, createSignal, mergeProps } from "solid-js";
import { A } from "@solidjs/router";
import { createStore } from "solid-js/store";
import { useSessionContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { checkCircle } from "solid-heroicons/outline";
import { iconPath } from "~/utils/icons";
import Button from "./Button";
import { PopupFields } from "./Popup.block";
import { BaseLoader, Modal } from "./utility";
import { TextFieldInput } from "./inputs";
import { Img } from "~/components/image";

const Popup: Component<PopupFields> = (inProps) => {
  const props = mergeProps(
    {
      // modalId: "test3",
      // mailchimpListID: "74855fd591",
      // icon: "Roma Logo",
      // header: "Be the first to know",
      // subHeader: "",
      // copy: "Sign up for Roma emails to be the first to see inspiring content, news and exclusive offers.",
      // image: "",
      // placeholderText: "Enter your email",
      // buttonText: "Sign Up",
      // submissionMessage: "Thank you for sigining up!",
    },
    inProps
  );

  const { session, setSession } = useSessionContext();
  const [open, setOpen] = createSignal(true);

  type Form = {
    id: string;
    email: string;
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  const [submissionForm, setSubmissionForm] = createStore<Form>({
    id: props.mailchimpListID,
    email: "",
    loading: false,
    success: false,
    error: null,
  });

  const handleSubmit = async () => {
    setSubmissionForm("loading", true);
    const response = await fetch(
      `${import.meta.env.VITE_ROMA_API}/newsletter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ListID: submissionForm.id,
          Email: submissionForm.email,
        }),
      }
    );
    setSubmissionForm("loading", false);
    if (response.status !== 200) {
      const resp = await response.json();
      switch (resp.Code) {
        case "INVALID_LIST":
          setSubmissionForm(
            "error",
            "Error with mailing list, please try again later."
          );
          break;
        case "ALREADY_REGISTERED":
          setSubmissionForm(
            "error",
            "This email has already been registered with this mailing list."
          );
          break;
        case "FAKE_EMAIL":
          setSubmissionForm(
            "error",
            "This email looks like it could potentially be fake. Please try again with another email address."
          );
          break;
        default:
          setSubmissionForm(
            "error",
            "Registration was not successfull, please contact us and try again."
          );
          break;
      }
      setSubmissionForm("success", false);
      return;
    }
    setSubmissionForm("success", true);
  };

  const registerModal = (id: string) => {
    setSession(`modal_${id}`, "true");
    return true;
  };

  return (
    <Show when={!session[`modal_${props.modalId}`]}>
      {/* <Show when={true}> */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          registerModal(props.modalId);
        }}
      >
        <div class="flex flex-col items-center gap-6">
          <div class="p-5">
            <Img
              src={iconPath[props.icon as string]}
              alt=""
              class="w-10"
              width={40}
              height={40}
            />
          </div>
          <h3 class="text-3xl text-center ">{props.header}</h3>
          <p class="text-center text-roma-dark-grey">{props.copy}</p>
          <Show when={props.image}>
            <img src={props.image} alt="" class="max-w-sm" />
          </Show>
          <Show
            when={submissionForm.success !== true}
            fallback={
              <>
                <Icon path={checkCircle} class="text-success-green w-12" />
                <p class="mt-2 text-lg">{props.submissionMessage}</p>
              </>
            }
          >
            <Show when={submissionForm.error !== null}>
              <div class="text-red-500 mb-2 p-3 text-center border-2 border-red-500">
                {submissionForm.error}
              </div>
            </Show>
            <form
              class="flex flex-col gap-6 w-full"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextFieldInput
                type="email"
                name="email"
                class="w-full !rounded-full"
                placeholder={props.placeholderText}
                onChange={(val) => setSubmissionForm("email", val)}
                disabled={submissionForm.loading}
                required
              />
              <Button
                type="submit"
                class="font-medium w-full"
                disabled={submissionForm.loading}
              >
                <span class="px-3">{props.buttonText}</span>
              </Button>
              <div class="flex justify-center">
                <Show when={submissionForm.loading}>
                  <BaseLoader width={6} height={6} />
                </Show>
              </div>
            </form>
          </Show>
          <p class="text-xs text-roma-medium-grey text-center">
            By signing up, you agree to Roma's{" "}
            <A href="/privacy" target="_blank" class="underline">
              Privacy Policy
            </A>{" "}
            and{" "}
            <A href="/privacy" target="_blank" class="underline">
              Terms of Use
            </A>
            . You may unsubscribe at any time
          </p>
          <A
            href="/support"
            class="text-sm text-roma-medium-grey text-center underline"
            onClick={() => setOpen(false)}
          >
            Contact us
          </A>
        </div>
      </Modal>
    </Show>
  );
};

export default Popup;
