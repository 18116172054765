import { Component, Show, For } from "solid-js";
import { ContentGridFields } from "./ContentGrid.block";
import { iconPath } from "~/utils/icons";
import { Img } from "~/components/image";

const ContentGrid: Component<ContentGridFields> = (props) => {
  return (
    <section class="flex flex-col items-center">
      <Show when={props.sectionTitle}>
        <h3 class="text-xl font-bold px-5 py-10 lg:py-20">
          {props.sectionTitle}
        </h3>
      </Show>
      <div class="w-full bg-roma-grey boundary-inner">
        <div class="grid md:grid-cols-3 gap-5 md:gap-10 lg:gap-14 py-8 lg:py-20 items-baseline">
          <For each={props.contentBlock}>
            {(item) => (
              <div class="flex flex-col gap-2">
                <div class="w-16 aspect-square ">
                  <Show when={item.isIcon === "Icon"}>
                    <img
                      src={iconPath[item.icon!]}
                      alt=""
                      class="w-full"
                      width={64}
                      height={64}
                    />
                  </Show>
                  <Show when={item.isIcon === "Upload"}>
                    <Show when={item.uploadedImage}>
                      <Img
                        src={item.uploadedImage as string}
                        alt=""
                        class="w-full h-full"
                        classList={{
                          "rounded-full object-cover": !!item.roundoverImage,
                          "object-fit": !item.roundoverImage,
                        }}
                        width={64}
                        height={64}
                      />
                    </Show>
                  </Show>
                </div>
                <Show when={item.title && item.contentType === "Infoblock"}>
                  <Show when={item.href}>
                    <a
                      href={item.href}
                      class="self-start underline-hover underline-black"
                    >
                      <h4 class="font-bold text-lg leading-8">{item.title}</h4>
                    </a>
                  </Show>
                  <Show when={!item.href}>
                    <h4 class="font-bold text-lg leading-8">{item.title}</h4>
                  </Show>
                </Show>
                <Show when={item.bodyText}>
                  <div class="leading-7">
                    <Show
                      when={item.contentType === "Testimonial"}
                      fallback={<p>{item.bodyText}</p>}
                    >
                      <blockquote class="md:pt-10">
                        “ {item.bodyText} ”
                      </blockquote>
                    </Show>
                  </div>
                </Show>
                <Show when={item.quotee && item.contentType === "Testimonial"}>
                  <cite class="font-bold text-right self-end">
                    –{item.quotee}
                  </cite>
                </Show>
              </div>
            )}
          </For>
        </div>
      </div>
    </section>
  );
};

export default ContentGrid;
