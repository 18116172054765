import { Component, Show, Accessor, createSignal, createMemo } from "solid-js";
import { Icon } from "solid-heroicons";
import { play } from "solid-heroicons/outline";
import { AllHandsArchiveFields } from "./AllHandsArchive.block";

const AHMVideoModal: Component<
  AllHandsArchiveFields & {
    posterImage: Accessor<string>;
    details: {
      name: string;
      data: {
        description: string;
        posterImage: string;
        youtubeId: string;
        publishAt: string;
      };
    };
  }
> = (props) => {
  const [details, setDetails] = createSignal(true);
  const date = createMemo(() =>
    new Date(props.details.data.publishAt).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
  );

  return (
    <>
      <div class="max-w-7xl mx-auto relative">
        <div class="w-full aspect-video">
          <Show when={!details()}>
            <iframe
              class="w-full aspect-video pt-2"
              src={`https://www.youtube.com/embed/${props.details.data.youtubeId}?autoplay=1&mute=1`}
              // @ts-ignore
              wmode="transparent"
              type="video/mp4"
              width="100%"
              height="100%"
              allow="autoplay; encrypted-media; picture-in-picture"
              allowfullscreen
              title={props.details.name}
            >
              Video playback not supported in this browser.
            </iframe>
          </Show>
        </div>
        <div class="flex gap-2 items-center mt-3">
          <h2 class="sm:text-xl font-bold">{props.details.name}</h2>
          <span>&bull;</span>
          <p class="sm:text-xl font-bold">{date()}</p>
        </div>
        <Show when={details()}>
          <div
            class="w-full bg-cover bg-center bg-no-repeat absolute inset-0"
            style={{ "background-image": `url('${props.posterImage()}')` }}
          >
            <div class="bg-black bg-opacity-50 absolute inset-0 flex flex-col items-center justify-evenly p-8 gap-8">
              <h3 class="text-white sm:text-xl lg:text-3xl font-bold">
                {props.details.name}
              </h3>
              <p class="text-white text-xs sm:text-base text-center max-w-lg">
                {props.details.data.description}
              </p>
              <div
                class="flex items-center gap-2 underline-hover"
                onClick={() => {
                  setDetails(false);
                }}
              >
                <button aria-label={`Watch ${props.details.name}`} class="text-white text-xl">Watch</button>
                <Icon path={play} class="w-5 text-white" />
              </div>
            </div>
          </div>
        </Show>
      </div>
    </>
  );
};

export default AHMVideoModal;
