import { Component, mergeProps, For, Show } from "solid-js";
import { A } from "@solidjs/router";
import { iconPath } from "~/utils/icons";
import { chevronRight } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";
import { Img } from "~/components/image";

type SGFields = {
  sectionTitle?: string;
  data: { title: string; href: string; icon: string }[];
};

const SupportGrid: Component<SGFields> = (inProps) => {
  const props = mergeProps(
    {
      sectionTitle:
        "To better service your needs 24/7, please select an area of assistance to improve your partner experience.",
    },
    inProps
  );

  return (
    <section class="w-full flex flex-col items-center py-10">
      <Show when={props.sectionTitle}>
        <h3 class="max-w-xl text-center text-2xl font-bold pb-20">
          {props.sectionTitle}
        </h3>
      </Show>
      <nav class="w-full boundary-outer grid sm:grid-cols-2 lg:grid-cols-3 gap-3">
        <For each={props.data}>
          {(item) => (
            <A href={item.href} class="group">
              <div class="w-full aspect-5/4 bg-roma-grey flex flex-col justify-center items-center relative">
                <img
                  src={iconPath[item.icon]}
                  width={128}
                  height={110}
                  class="w-20 xl:w-32 sm:group-hover:scale-110 transition"
                  alt=""
                />
                <div class="absolute bottom-8 inline-flex justify-center items-center">
                  <h3 class="text-lg font-bold ml-4">{item.title}</h3>
                  <div class="rounded-full border aspect-square inline-flex items-center justify-center p-[2px] ml-2">
                    <Icon path={chevronRight} class="w-3" />
                  </div>
                </div>
              </div>
            </A>
          )}
        </For>
      </nav>
    </section>
  );
};

export default SupportGrid;
