import CTA from "./CTA";
import { enumIcons } from "~/utils/builder/enums";

const inputs = [
  {
    name: "title",
    type: "string",
    defaultValue: "Title Text",
  },
  {
    name: "headingLevel",
    friendlyName: "Heading Level",
    type: "string",
    enum: ["h1", "h2", "h3", "h4", "p"],
    default: "h2",
  },
  {
    name: "description",
    type: "richText",
    defaultValue: "Enter a short description / details here",
    onChange: (options: any, parent: any) => {
      if (options.get("description").length > 125) {
        options.set(
          "description",
          options.get("description").substring(0, 125)
        );
        alert("Please shorten the description, max characters is 125");
      }
    },
  },
  {
    name: "icon",
    type: "string",
    enum: enumIcons,
    defaultValue: "Roma Logo",
  },
] as const;

export type CTAFields = BuilderFields<GenerateItems<typeof inputs>>;

const CTABlock = {
  component: CTA,
  name: "Cta",
  friendlyName: "CTA Single",
  section: "Content Components",
  inputs,
  canHaveChildren: true,
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fa415d953714d4a5fb8c7f447d7473883",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F1fbe693506c94845840329a528a8cf06",
};

export default CTABlock;
