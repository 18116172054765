import Countdown from "./Countdown";

const inputs = [
  {
    name: "date",
    friendlyName: "Date",
    type: "date",
    required: true,
  },
  {
    name: "details",
    friendlyName: "Details",
    type: "longText",
    defaultValue: "A short message with the details about the upcoming event",
    helperText: "A short description",
  },
  {
    name: "postDateMessage",
    friendlyName: "Post-Date Message",
    type: "longText",
    helperText: "Write a message to display after the date has passed",
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: false,
    helperText: "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab"
  },
  {
    name: "buttonLabel",
    friendlyName: "Button Label",
    type: "string"
  },
  {
    name:"href",
    friendlyName: "Button Link",
    type: "string"
  },
  {
    name:"newTab",
    friendlyName: "Open in new tab?",
    type: "boolean",
    defaultValue: false
  }
] as const;

export type CountdownFields = BuilderFields<GenerateItems<typeof inputs>>;

const CountdownBlock = {
  component: Countdown,
  name: "Countdown",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F79d7c1f441824a6285a7a98b96d8312d",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fe669c38ded7540babb7951684f2ce8fa",
  inputs,
};

export default CountdownBlock;
