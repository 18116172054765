
import { type RegisteredComponent } from "@builder.io/sdk-solid";
import BodyBlock from "~/components/Body.block";
import HeaderBlock from "~/components/header/Header.block";
import FooterBlock from "~/components/Footer.block";
import BannerInnerContentBlock from "~/components/BannerInnerContent.block";
import ProductSliderBlock from "~/components/ProductSlider.block";
import CallToActionBlock from "~/components/CallToAction.block";
import HeaderWithContentBlock from "~/components/HeaderWithContent.block";
import ImageWithContentBlock from "~/components/ImageWithContent.block";
import ShortQuoteBlock from "~/components/ShortQuote.block";
import ContentGridBlock from "~/components/ContentGrid.block";
import FormBlock from "~/components/Form.block";
import BreadcrumbBlock from "~/components/Breadcrumb.block";
import FeatureVideoBlock from "~/components/FeatureVideo.block";
import CardFeatureBlock from "~/components/CardFeature.block";
import LongQuoteBlock from "~/components/LongQuote.block";
import MasonryGridBlock from "~/components/MasonryGrid.block";
import CountdownBlock from "~/components/Countdown.block";
import AllHandsArchiveBlock from "~/components/AllHandsArchive.block";
import TextCopyWithButtonBlock from "~/components/TextCopyWithButton.block";
import OpenPositionsBlock from "~/components/OpenPositions.block";
import LogoCloudBlock from "~/components/LogoCloud.block";
import RomaTourBlock from "~/components/RomaTour.block";
import BannerBlock from "~/components/Banner.block";
import SupportGridBlock from "~/components/SupportGrid.block";
// review
import StoryThumbailBlock from "~/components/StoryThumbnail.block";
import CTABlock from "~/components/CTA.block";
import FeatureBlock from "~/components/Feature.block";
import PopupBlock from "~/components/Popup.block";
// TODO: Components in prev that have not been imported - possibly not necessary/unused?:

//! SliderBlock, => IS NOT USED, deprecated - NOT INCLUDED
//? StoryThumbailBlock, => used internally, not on builder
// !PressListingBlock, => not used anywhere - NOT INCLUDED
//? CTABlock, => used by CTAAction, does not to be used directly in builer
//! ImageBlockBlock, => not used anywhere, wtf was this - NOT INCLUDED
//! CTAPanelBlock, => does not appear to be used anywhere, likely was a used in place of Builder Columns - NOT INCLUDED
//TODO ShareBlock, => was never implemented, readdress @solid-primitive/share
//? FeatureBlock, => not used directly in builder, composed by CardFeature and FeatureVideo
//! NewsletterBlock, => Unused - NOT INCLUDED
//? PopupBlock, => not currently used, was prev used but should be tested again
//! ColumnContainerBlock => was used when Builder was janky - the column/stack shift has been resolved - NOT INCLUDED

export const builderComponents = [
  BodyBlock,
  HeaderBlock,
  FooterBlock,
  BannerInnerContentBlock,
  ProductSliderBlock,
  CallToActionBlock,
  HeaderWithContentBlock,
  ImageWithContentBlock,
  ShortQuoteBlock,
  ContentGridBlock,
  FormBlock,
  BreadcrumbBlock,
  FeatureVideoBlock,
  CardFeatureBlock,
  LongQuoteBlock,
  MasonryGridBlock,
  CountdownBlock,
  AllHandsArchiveBlock,
  TextCopyWithButtonBlock,
  OpenPositionsBlock,
  LogoCloudBlock,
  RomaTourBlock,
  BannerBlock,
  SupportGridBlock,
  // review
  StoryThumbailBlock,
  CTABlock,
  FeatureBlock,
  PopupBlock,
] as RegisteredComponent[];
