import { Component, createSignal, mergeProps, Show } from "solid-js";
import { CountdownFields } from "./Countdown.block";
import {
  createDate,
  createCountdownFromNow,
  createTimeDifferenceFromNow,
} from "@solid-primitives/date";
import { iconPath } from "~/utils/icons";
import Button from "./Button";
import { Img } from "~/components/image";

const Countdown: Component<CountdownFields> = (inProps) => {
  const today = new Date();
  const defaultDate = today.setDate(today.getDate() + 1);
  const props = mergeProps({ date: defaultDate }, inProps);
  const [date] = createDate(props.date);
  const [to] = createSignal(Math.floor(date().getTime()));
  const [countdown] = createCountdownFromNow(to);
  const [diff] = createTimeDifferenceFromNow(to);
  return (
    <section
      class="w-full bg-roma-grey flex flex-col justify-center items-center gap-10 py-16"
      classList={{ "boundary-outer": !props.fullWidth }}
    >
      <div class="w-10">
        <Img
          src={iconPath["Stopwatch"]}
          width={40}
          height={40}
          layout="constrained"
          alt="Stopwatch icon"
        />
      </div>
      <Show when={diff() > 0}>
        <div class="bg-white py-10 pb-7 p-12 text-4xl md:text-7xl font-bold flex flex-row gap-3 rounded-lg">
          <div class="flex flex-col items-center">
            <p>{countdown.days?.toString().padStart(2, "0")}</p>
            <p class="text-lg">Days</p>
          </div>
          <p>:</p>
          <div class="flex flex-col items-center">
            <p>{countdown.hours?.toString().padStart(2, "0")}</p>
            <p class="text-lg">Hours</p>
          </div>
          <p>:</p>
          <div class="flex flex-col items-center">
            <p>{countdown.minutes?.toString().padStart(2, "0")}</p>
            <p class="text-lg">Minutes</p>
          </div>
          <p>:</p>
          <div class="flex flex-col items-center">
            <p>{countdown.seconds?.toString().padStart(2, "0")}</p>
            <p class="text-lg">Seconds</p>
          </div>
        </div>
      </Show>
      <Show
        when={diff() > 0}
        fallback={
          <h4 class="md:text-xl font-bold text-center">
            {props.postDateMessage}
          </h4>
        }
      >
        <h4 class="md:text-xl font-bold text-center">{props.details}</h4>
      </Show>
      <Show when={props.buttonLabel && props.href}>
        <Button
          generic={props.newTab ?? undefined}
          target={props.newTab ? "_blank" : undefined}
          href={props.href}
        >
          <span class="px-2">{props.buttonLabel}</span>
        </Button>
      </Show>
    </section>
  );
};

export default Countdown;
