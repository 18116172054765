import Footer from "./Footer";

const inputs = [
  {
    name: "navigation",
    type: "list",
    subFields: [
      {
        name: "label",
        type: "string",
        defaultValue: "",
        require: true,
      },
      {
        name: "url",
        type: "url",
        defaultValue: "",
        require: true,
      },
      {
        name: "links",
        type: "list",
        subFields: [
          {
            name: "label",
            type: "string",
            defaultValue: "",
            require: true,
          },
          {
            name: "url",
            type: "url",
            defaultValue: "",
            require: true,
          },
          {
            name: "icon",
            type: "file",
            allowedFileTypes: ["png", "svg"],
          },
          {
            name: "highlight",
            type: "boolean",
          },
          {
            name: "newTab",
            type: "boolean",
            defaultValue: "false",
            friendlyName: "Open in new tab?"
          },
        ],
      },
    ],
  },
] as const;

export type FooterFields = BuilderFields<GenerateItems<typeof inputs>>;

export const FooterBlock = {
  component: Footer,
  noWrap: true,
  name: "Footer",
  inputs,
};

export default FooterBlock;
