import Popup from "./Popup";
import { enumIcons } from "~/utils/builder/enums";

const inputs = [
  {
    name: "modalId",
    type: "string",
    friendlyName: "Popup ID",
    helperText:
      "This ID must be unqiue, and contain no spaces. Once a user interacts with this specific popup (closes or signs up), they will not be shown it again. Change the ID or create a new popup when the content changes and you want all visitors to see it.",
    required: true,
  },
  {
    name: "mailchimpListID",
    friendlyName: "MailChimp List ID",
    type: "string",
    required: true,
    helperText:
      'Mailchimp list ID is available inside the "Settings" tab within the "Audience" section',
  },
  {
    name: "icon",
    type: "string",
    enum: enumIcons,
    defaultValue: "Roma Logo",
  },
  {
    name: "header",
    type: "string",
    defaultValue: "Be the first to know",
  },
  {
    name: "copy",
    type: "longText",
    defaultValue:
      "Sign up for Roma emails to be the first to see inspiring content, news and exclusive offers.",
  },
  {
    name: "image",
    type: "file",
    allowedFileTypes: ["svg", "png", "jpeg", "jpg"],
  },
  {
    name: "placeholderText",
    friendlyName: "Placeholder Text",
    type: "string",
    defaultValue: "Enter your email",
  },
  {
    name: "buttonText",
    type: "string",
    defaultValue: "Sign Up",
  },
  {
    name: "submissionMessage",
    type: "string",
    defaultValue: "Thank you for sigining up!",
    helperText: "This text shows after a successful submission",
  },
] as const;

export type PopupFields = BuilderFields<GenerateItems<typeof inputs>>;

const PopupBlock = {
  component: Popup,
  name: "Popup",
  section: "Interactive Components",
  inputs,
};

export default PopupBlock;
