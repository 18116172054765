import Body from "./Body";

const BodyBlock = {
  component: Body,
  name: "Body",
  section: "Layout Components",
  inputs: [],
  noWrap: true,
  canHaveChildren: true,
  defaultChildren: [
    {
      "@type": "@builder.io/sdk:Element",
      component: { name: "Text", options: { text: "I am child text block!" } },
    },
  ],
};

export default BodyBlock;