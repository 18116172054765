import BannerInnerContent from "./BannerInnerContent";
import {
  enumAspectRatio,
  enumBgPosition,
  enumFilter,
  enumTextSize,
  enumContentWidth,
} from "~/utils/builder/enums";
import placeholder from "~/assets/placeholder.png";

const inputs = [
  {
    name: "bannerType",
    type: "string",
    enum: ["Image", "Video"],
    defaultValue: "Image",
  },
  {
    name: "bannerImage",
    friendlyName: "Background Image",
    type: "file",
    allowedFileTypes: ["jpg", "jpeg", "png", "svg"],
    defaultValue: placeholder,
  },
  {
    name: "bannerAlt",
    friendlyName: "Image Alt Text",
    type: "string",
  },
  {
    name: "bannerVideo",
    friendlyName: "Background Video",
    type: "file",
    allowedFileTypes: ["mp4"],
    showIf: "options.get('bannerType') === 'Video'",
    helperText:
      "When using a video background, an image should still be uploaded below for accessibility and to act as a placeholder if the video fails to load. If you have the direct link to the hosted video file, use the 'Video Source' option below",
  },
  {
    name: "bannerVideoSrc",
    friendlyName: "(Optional) - Video Source",
    type: "string",
    showIf: "options.get('bannerType') === 'Video'",
    helperText:
      "Only use if you have the direct link to a video source (url should end in .mp4). Do not use both this field & the video upload, one or the other.",
  },
  {
    name: "linkToExternalVideo",
    friendlyName: "Add YouTube video player within banner?",
    type: "boolean",
    defaultValue: false,
  },
  {
    name: "youtubeId",
    friendlyName: "YouTube ID",
    type: "string",
    helperText:
      "The id is found at the end of the url (eg. https://www.youtube.com/watch?v=UNnvYrQcKzI [UNnvYrQcKzI is the id])",
    showIf: 'options.get("linkToExternalVideo") == true',
  },
  {
    name: "videoHasAudio",
    friendlyName: "Does Video Contain Audio?",
    type: "boolean",
    defaultValue: false,
    showIf: "options.get('bannerType') === 'Video'",
    helperText:
      "All videos will play muted - if the video contains audio, an unmute button will display alongisde the play/pause button",
  },
  {
    name: "videoPos",
    friendlyName: "Video Position",
    type: "string",
    enum: enumBgPosition,
    defaultValue: "Center",
    helperText:
      "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio.",
    showIf: "options.get('bannerType') === 'Video'",
  },
  {
    name: "aspectRatioLg",
    friendlyName: "Aspect Ratio - Large",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "7:3",
    helpertext: "This aspect ratio will show on tablet and desktop",
    // @ts-expect-error
    onChange: (options, parent) => {
      options.set("aspectRatioSm", options.get("aspectRatioLg"));
    },
  },
  {
    name: "layout",
    friendlyName: "Layout",
    type: "string",
    enum: ["Full Width", "2-Column"],
    default: "Full Width",
  },
  {
    name: "aspectRatioSm",
    friendlyName: "Aspect Ratio - Small",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "7:3",
    helpertext: "This aspect ratio will show on mobile",
  },
  {
    name: "bgPosition",
    friendlyName: "Background Position",
    type: "string",
    enum: enumBgPosition,
    defaultValue: "Center",
    helperText:
      "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio.",
    showIf: "options.get('bannerType') === 'Image'",
  },
  {
    name: "bgFilter",
    friendlyName: "Darken / Lighten Image?",
    type: "string",
    enum: enumFilter,
    defaultValue: "None",
    helperText: "Darken or Lighten the image so that the text is more legible",
  },
  {
    name: "textColour",
    friendlyName: "Text Color",
    type: "string",
    enum: ["White", "Black"],
    defaultValue: "Black",
  },
  {
    name: "header",
    type: "string",
    defaultValue: "Header Text",
  },
  {
    name: "headerTextSize",
    friendlyName: "Header Text Size",
    type: "string",
    enum: enumTextSize,
    defaultValue: "Extra-Large",
  },
  {
    name: "headingLevel",
    friendlyName: "Heading Level",
    type: "string",
    enum: ["h1", "h2", "h3", "h4", "p"],
    default: "h2",
  },
  {
    name: "subheader",
    type: "string",
    defaultValue: "Subheader Text",
  },
  {
    name: "subheaderTextSize",
    friendlyName: "Header Text Size",
    type: "string",
    enum: enumTextSize,
    defaultValue: "Small",
  },
  {
    name: "swapHeaderPos",
    friendlyName: "Swap Header & Subheader Position?",
    type: "boolean",
    defaultValue: false,
    helperText: "Toggle to make the header below the subheader",
  },
  {
    name: "contentWidth",
    friendlyName: "Text Content Width",
    type: "string",
    enum: enumContentWidth,
    defaultValue: "Full",
    helperText: "Use if the content needs to be constrained",
  },
  {
    name: "contentPosition",
    friendlyName: "Inner Content Position",
    type: "string",
    enum: enumBgPosition,
    defaultValue: "Bottom-Left",
  },
  {
    name: "buttons",
    type: "list",
    subFields: [
      {
        name: "label",
        type: "string",
        defaultValue: "Learn More",
      },
      {
        name: "altLabel",
        type: "string",
        friendlyName: "Descriptive Label",
        helperText:
          "If using a broad label such as 'Learn More', provide a more descriptive label for SEO & accessibility here, ie. 'Learn about our sustainability initiatives', etc",
      },
      {
        name: "href",
        type: "string",
        defaultValue: "/",
      },
      {
        name: "openNewTab",
        friendlyName: "Open In New Tab?",
        type: "boolean",
        defaultValue: false,
      },
    ],
    defaultValue: [
      {
        label: "Learn More",
        href: "/",
      },
    ],
  },
  {
    name: "buttonColour",
    type: "string",
    enum: ["Black", "White"],
  },
  {
    name: "playButtonText",
    friendlyName: "Play Button Text",
    type: "string",
    defaultValue: "Watch Film",
    showIf: 'options.get("linkToExternalVideo") == true',
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: true,
    helperText:
      "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab",
  },
  {
    name: "loading",
    friendlyName: "Image Loading",
    type: "string",
    enum: ["lazy", "eager"],
    default: "lazy",
  },
  {
    name: "fetchPriority",
    friendlyName: "Fetch Priority",
    type: "string",
    enum: ["high", "low", "auto"],
    default: "auto",
  },
] as const;

export type BannerInnerContentFields = BuilderFields<
  GenerateItems<typeof inputs>
>;

const BannerInnerContentBlock = {
  component: BannerInnerContent,
  name: "BannerInnerContent",
  friendlyName: "Banner with Inner Content",
  section: "Content Components",
  inputs,
};

export default BannerInnerContentBlock;
