import LogoCloud from "./LogoCloud";

const inputs = [
  {
    name: "sectionTitle",
    friendlyName: "Section Title",
    type: "string",
    defaultValue: "My new logo cloud",
    helperText: "Optional section title - replace or remove",
  },
  {
    name: "logoBlock",
    friendlyName: "Logo Block",
    type: "list",
    subFields: [
      {
        name: "name",
        type: "string",
        required: true,
      },
      {
        name: "image",
        friendName: "Upload Logo",
        type: "file",
        allowedFileTypes: ["svg", "png", "jpeg", "jpg"],
        helperText: "Image should have a white or transparent background",
      },
      {
        name: "url",
        friendlyName: "Link Address",
        type: "string",
      },
    ],
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: false,
    helperText: "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab"
  }
] as const;

export type LogoCloudFields = BuilderFields<GenerateItems<typeof inputs>>;

const LogoCloudBlock = {
  component: LogoCloud,
  name: "LogoCloud",
  friendlyName: "Logo Cloud",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F6eebc30a71db4a78b7bcca1898a28b04",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F747657fccb0640ff922b6cd81b82581b",
  inputs,
};

export default LogoCloudBlock;
