import { Component, Show, For } from "solid-js";
import { LogoCloudFields } from "./LogoCloud.block";
import { Img } from "~/components/image";

const LogoCloud: Component<LogoCloudFields> = (props) => {
  return (
    <section
      class="flex flex-col items-center"
      classList={{ "boundary-outer": !props.fullWidth }}
    >
      <Show when={props.sectionTitle}>
        <h3 class="text-xl font-bold px-5 py-5 md:py-10 text-center">
          {props.sectionTitle}
        </h3>
      </Show>
      <div class="px-10 flex flex-row flex-wrap justify-center items-center gap-4 lg:gap-14 w-full">
        <Show when={props.logoBlock} fallback={<p>No logos supplied</p>}>
          <For each={props.logoBlock}>
            {(item) => (
              <div
                title={item.name}
                class="basis-20 md:basis-32 grow-1 flex-shrink"
              >
                <Show
                  when={item.url}
                  fallback={
                    <Img
                      src={item.image as string}
                      alt={`Logo for ${item.name}`}
                      layout="fullWidth"
                    />
                  }
                >
                  <a href={item.url} target="_blank" rel="noopener">
                    <Img
                      src={item.image as string}
                      alt={`Logo for ${item.name}`}
                      layout="fullWidth"
                    />
                  </a>
                </Show>
              </div>
            )}
          </For>
        </Show>
      </div>
    </section>
  );
};

export default LogoCloud;
