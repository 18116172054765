import StoryThumbnail from "./StoryThumbnail";
import placeholder from "~/assets/placeholder.png"

const inputs = [
  {
    name: "title",
    type: "string",
    defaultValue: "Article Title",
  },
  {
    name: "href",
    type: "string",
    defaultValue: "#",
  },
  {
    name: "image",
    type: "file",
    allowedFileTypes: ["png", "jpg", "jpeg", "svg"],
    defaultValue: placeholder,
  },
  {
    name: "bannerAlt",
    friendlyName: "Banner Alt Text",
    type: "string",
    helperText: "Describe the image for accessibility and SEO",
  },
  {
    name: "description",
    type: "longText",
    defaultValue:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus voluptas, mollitia a consectetur commodi eligendi ea culpa unde facere ab. Recusandae dolorum modi nulla est quos, eius vero distinctio assumenda.",
  },
  {
    name: "date",
    type: "date",
  },
  {
    name: "category",
    type: "string",
    defaultValue: "Category Name",
  },
] as const;

export type StoryThumbnailFields = BuilderFields<GenerateItems<typeof inputs>>;

const StoryThumbailBlock = {
  component: StoryThumbnail,
  name: "StoryThumbnail",
  section: "Content Components",
  friendlyName: "Story Thumbnail",
  inputs,
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F115de77884d7492ab85f5fb2e993f238",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F3a3ea5553f034cc69501b0d6ad749519",
};

export default StoryThumbailBlock;
