import {
  Component,
  Show,
  createSignal,
  mergeProps,
  createMemo,
} from "solid-js";
import { play, chevronRight, xMark } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";
import { A } from "@solidjs/router";
import { FeatureVideoFields } from "./FeatureVideo.block";
import { bgFilter, aspectRatio } from "~/utils/builder/enums";
import Feature from "~/components/Feature";


const FeatureVideo: Component<FeatureVideoFields> = (inProps) => {
  const [showPoster, setShowPoster] = createSignal(true);
  const props = mergeProps(
    {
      header: "Title Goes Here",
      youtubeId: "Y7g0mhjBNvs",
      playText: "Watch The Film",
      buttonPosition: "Center",
      bgPosition: "Center",
      showIcon: true,
      fullBleed: false,
      aspectRatio: "7:3",
      imageFilter: "Darken-1",
    },
    inProps
  );
  const image = createMemo(() => {
    return props.posterImage
      ? props.posterImage
      : `https://img.youtube.com/vi/${props.youtubeId}/maxresdefault.jpg`;
  });
  return (
    <section
      class="w-full py-2"
      classList={{ "boundary-outer": !props.fullWidth }}
    >
      <div
        class="w-full relative"
        classList={{
          [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
          [`sm:${aspectRatio[props.aspectRatioLg]}`]: !!props.aspectRatioLg,
        }}
      >
        <Show when={!showPoster()}>
          <div class="flex justify-end items-center text-black pb-2">
            <button
              aria-label="Close"
              class="inline-flex items-center text-xs sm:text-base font-medium"
              onClick={() => {
                setShowPoster(true);
              }}
            >
              <Show when={!!props.showIcon}>
                <Icon path={xMark} class="w-4 mr-2" />
              </Show>
              <span class="underline-hover underline-black">Close Video</span>
            </button>
          </div>
          <iframe
            class="w-full aspect-video"
            src={`https://www.youtube.com/embed/${props.youtubeId}?autoplay=1&rel=0`}
            // @ts-ignore
            wmode="transparent"
            type="video/mp4"
            width="100%"
            height="100%"
            allow="autoplay; encrypted-media; picture-in-picture"
            allowfullscreen
            title={props.header}
          >
            Video playback not supported in this browser.
          </iframe>
        </Show>
        <Show when={showPoster()}>
          <Feature
            aspectRatioLg={props.aspectRatioLg}
            aspectRatioSm={props.aspectRatioSm}
            bannerImage={image()}
            bannerAlt={props.header}
            bgPosition={props.bgPosition}
            showPlayButton={true}
            onPlayClick={() => {
              setShowPoster(false);
            }}
            playButtonText={props.playText}
            buttonPosition={props.buttonPosition}
            bgFilter={props.imageFilter}
            upperTitle={props.subheader}
            title={props.header}
            titleSize={props.titleSize}
            buttons={props.buttons as any}
          />
        </Show>
      </div>
    </section>
  );
};

export default FeatureVideo;
