import {
  Component,
  For,
  createMemo,
  Show,
  mergeProps,
  createSignal,
} from "solid-js";
import { createAsync } from "@solidjs/router";
import { getBuilderAhmEntries } from "~/services/builder";
import ModalWindow from "~/components/ModalWindow";
import AHMVideoModal from "~/components/AHMVideoModal";

import Button from "./Button";
// import Modal from "./Modal";

export type AhmModel = {
  name: string;
  data: {
    title: string;
    description: string;
    publishAt: object;
    slug: string;
    source: string;
    youtubeId: string;
    image: string;
    seoKeywords: string[];
    seoDescription: string;
  };
};

export const AllHandsCard: Component<{ details: any }> = (props) => {
  const [openModal, setOpenModal] = createSignal(false);
  const posterImage = createMemo(() =>
    props.details.data.image
      ? props.details.data.image
      : `https://img.youtube.com/vi/${props.details.data.youtubeId}/maxresdefault.jpg`
  );
  const formattedDate = createMemo(() =>
    new Date(props.details.data.publishAt).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
  );
  return (
    <>
      <div class="flex flex-col md:gap-3">
        {/* ! changed from div to a */}
        <a
          class="group peer w-full aspect-4/3 bg-cover bg-no-repeat bg-center mb-2 md:mb-4 cursor-pointer relative"
          style={{ "background-image": `url('${posterImage()}')` }}
          // onClick={() => {
          //   setOpenModal(true);
          // }}
          href={`https://www.youtube.com/watch?v=${props.details.data.youtubeId}`}
          target="_blank"
        >
          <div class="group-hover:backdrop-brightness-50 absolute inset-0 transition duration-300" />
        </a>
        <h3 class="md:text-xl font-bold">{props.details.name}</h3>
        <div class="flex text-sm gap-1 peer-hover:first:child:text-roma-blue">
          <a
            class="font-medium hover:text-roma-blue"
            // onClick={() => {
            //   setOpenModal(true);
            // }}
            href={`https://www.youtube.com/watch?v=${props.details.data.youtubeId}`}
            target="_blank"
          >
            Watch
          </a>
          <span>&bull;</span>
          <p>{formattedDate()}</p>
        </div>
      </div>
      {/* <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        width="full"
      > */}
      <ModalWindow
        open={openModal}
        onClose={() => setOpenModal(false)}
        size="Large"
      >
        <AHMVideoModal details={props.details} posterImage={posterImage} />
      </ModalWindow>
      {/* </Modal> */}
    </>
  );
};

const AllHandsArchive: Component = (inProps) => {
  const content = createAsync(() => getBuilderAhmEntries());
  const props = mergeProps(
    {
      sectionTitle: "Watch Past Meetings",
    },
    inProps
  );
  const [showList, setShowList] = createSignal(false);
  return (
    <Show when={content()}>
      <section class="w-full flex flex-col py-8">
        <Show when={props.sectionTitle}>
          <h2 class="text-3xl font-bold text-center pb-8">
            {props.sectionTitle}
          </h2>
        </Show>
        <div class="grid md:grid-cols-3 gap-8">
          <For each={content()}>
            {(item) => (
              <div classList={{ "[&:nth-child(n+10)]:hidden": !showList() }}>
                <AllHandsCard details={item} />
              </div>
            )}
          </For>
        </div>
        <div class="self-center mt-8">
          <Button
            onClick={() => {
              setShowList(!showList());
            }}
            class="!px-8"
            style="solid"
          >
            {!showList() ? "Show More" : "Show Less"}
          </Button>
        </div>
      </section>
    </Show>
  );
};

export default AllHandsArchive;
