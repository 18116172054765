import { Component, Show, Switch, Match, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import { useNavigate } from "@solidjs/router";
import { useSessionContext } from "~/utils/contexts";
import { type CallToActionFields } from "./CallToAction.block";
import CTA from "./CTA";
import { TextFieldInput } from "./inputs";
import Button from "./Button";
import { Icon } from "solid-heroicons";
import { checkCircle, envelope, mapPin } from "solid-heroicons/outline";
import { Loader } from "./utility";
import { launchLiveChat } from "~/utils/livechat";

const CTAAction: Component<CallToActionFields["list"][number]> = (props) => {
  const navigate = useNavigate();
  const [zipPostal, setZipPostal] = createSignal<undefined | string>(undefined);
  const { session, isPartner } = useSessionContext();
  const [submissionForm, setSubmissionForm] = createStore<{
    id: string;
    email: string;
    loading: boolean;
    success: boolean;
    error: null | string;
  }>({
    id: props.mailchimpListID,
    email: "",
    loading: false,
    success: false,
    error: null,
  });

  const handleNewsletterSubmit = async () => {
    setSubmissionForm("loading", true);
    const response = await fetch(
      `${import.meta.env.VITE_ROMA_API}/newsletter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ListID: submissionForm.id,
          Email: submissionForm.email,
        }),
      }
    );
    setSubmissionForm("loading", false);
    if (response.status !== 200) {
      const resp = await response.json();
      switch (resp.Code) {
        case "INVALID_LIST":
          setSubmissionForm(
            "error",
            "Error with mailing list, please try again later."
          );
          break;
        case "ALREADY_REGISTERED":
          setSubmissionForm(
            "error",
            "This email has already been registered with this mailing list."
          );
          break;
        case "FAKE_EMAIL":
          setSubmissionForm(
            "error",
            "This email looks like it could potentially be fake. Please try again with another email address."
          );
          break;
        default:
          setSubmissionForm(
            "error",
            "Registration was not successful, please contact us and try again."
          );
      }
      setSubmissionForm("success", false);
      return;
    }
    setSubmissionForm("success", true);
  };

  const handlePartnerSearchSubmit = (code: undefined | string) => {
    if (code) navigate(`/support/authorized-retailers?searchLocation=${code}`);
  };

  const handleChatSubmit = () => {
    launchLiveChat(
      isPartner() && session.name ? session.name : "",
      isPartner() && session.company_number ? session.company_number : ""
    );
  };

  return (
    <CTA
      icon={props.icon}
      title={props.title}
      description={props.copy}
      contentWidth={props.contentWidth}
    >
      <Show
        when={submissionForm.success !== true}
        fallback={
          <>
            <Icon path={checkCircle} class="text-success-green w-12" />
            <p class="mt-2">{props.submissionMessage}</p>
          </>
        }
      >
        <Show when={submissionForm.error !== null}>
          <div class="text-red-500 mb-2 p-3 text-center border-2 border-red-500">
            {submissionForm.error}
          </div>
        </Show>
        <Show when={props.type === "Newsletter"}>
          <form
            class="flex flex-wrap gap-1"
            onSubmit={(e) => {
              e.preventDefault();
              handleNewsletterSubmit();
            }}
          >
            <div
              class="relative transition-colors basis-48 grow-[2]"
              classList={{ "text-gray-300": !!submissionForm.loading }}
            >
              <TextFieldInput
                name="cta_email"
                type="email"
                required
                placeholder={props.placeholderText}
                onChange={(val) => setSubmissionForm("email", val)}
                class="!rounded-full py-2.5"
                icon={envelope}
              />
              <div class=" absolute  top-2 right-0 w-8 block">
                <Loader show={submissionForm.loading} height="6" width="6" />
              </div>
            </div>
            <div class="basis-24 grow">
              <Button
                type="submit"
                class="font-medium w-full"
                disabled={submissionForm.loading}
              >
                <span class="px-3 whitespace-nowrap">{props.buttonText}</span>
              </Button>
            </div>
          </form>
        </Show>
        <Show when={props.type === "Action"}>
          <div>
            <Switch>
              <Match when={props.actionType === "Link"}>
                <Button href={props.actionValue} class="font-medium !w-full">
                  <span class="px-3">{props.buttonText}</span>
                </Button>
              </Match>
              <Match when={props.actionType === "Phone"}>
                <Button
                  href={`tel:${props.actionValue}`}
                  generic
                  class="font-medium !w-full"
                >
                  <span class="px-3">{props.buttonText}</span>
                </Button>
              </Match>
              <Match when={props.actionType === "Email"}>
                <Button
                  href={`mailto:${props.actionValue}`}
                  generic
                  class="font-medium"
                >
                  <span class="px-3">{props.buttonText}</span>
                </Button>
              </Match>
              <Match when={props.actionType === "Chat"}>
                <Button
                  onClick={() => handleChatSubmit()}
                  class="font-medium w-full"
                >
                  <span class="px-3">{props.buttonText}</span>
                </Button>
              </Match>
              <Match when={props.actionType === "Find A Retailer"}>
                <form
                  class="flex flex-wrap gap-1"
                  // classList={{" flex-col items-center" : props.stackButton}}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePartnerSearchSubmit(zipPostal());
                  }}
                >
                  <div
                    class="relative transition-colors basis-48 grow-[2]"
                    classList={{ "text-gray-300": !!submissionForm.loading }}
                  >
                    <TextFieldInput
                      name="zip-postal"
                      required
                      class="!rounded-full py-2.5"
                      placeholder="Enter a zip or postal code"
                      onChange={(val) => setZipPostal(val)}
                      icon={mapPin}
                    />
                    <div class=" absolute  top-2 right-0 w-8 block">
                      <Loader
                        show={submissionForm.loading}
                        height="6"
                        width="6"
                      />
                    </div>
                  </div>
                  <div class="basis-24 grow">
                    <Button
                      type="submit"
                      class="font-medium w-full"
                      disabled={submissionForm.loading}
                    >
                      <span class="px-3 whitespace-nowrap">
                        {props.buttonText}
                      </span>
                    </Button>
                  </div>
                </form>
              </Match>
            </Switch>
          </div>
        </Show>
      </Show>
    </CTA>
  );
};

export default CTAAction;
