import Header from "./Header";

const inputs = [
  // {
  //   name: "announcements",
  //   type: "list",
  //   subFields: [
  //     {
  //       name: "copy",
  //       type: "string",
  //       defaultValue: "",
  //       require: true,
  //     },
  //     {
  //       name: "buttonLabel",
  //       type: "string",
  //       defaultValue: "",
  //     },
  //     {
  //       name: "buttonUrl",
  //       type: "url",
  //       defaultValue: "",
  //     },
  //   ],
  // },
  {
    name: "navigation",
    type: "list",
    subFields: [
      {
        name: "label",
        type: "string",
        defaultValue: "",
        require: true,
      },
      {
        name: "url",
        type: "url",
        defaultValue: "",
      },
      {
        name: "categories",
        type: "list",
        subFields: [
          {
            name: "label",
            type: "string",
            defaultValue: "",
            require: true,
          },
          {
            name: "url",
            type: "url",
            defaultValue: "",
            require: true,
          },
          {
            name: "contentType",
            type: "string",
            enum: ["None", "Contact Block"],
            helperText: "Experimental - option to add additional content types to nav"
          },
          {
            name: "links",
            type: "list",
            subFields: [
              {
                name: "label",
                type: "string",
                defaultValue: "",
                require: true,
              },
              {
                name: "url",
                type: "string",
                defaultValue: "",
                require: true,
              },
              {
                name: "icon",
                type: "file",
                allowedFileTypes: ["png", "svg", "jpeg", "jpg"],
              },
              {
                name: "highlight",
                type: "boolean",
              },
              {
                name: "isNew",
                type: "boolean",
                friendlyName: "Tag as New",
                defaultValue: false,
              },
              {
                name: "hasColour",
                friendlyName: "Add Colour Icon?",
                type: "boolean",
                defaultValue: false,
              },
              {
                name: "colour",
                type: "color",
                showIf: `options.get('hasColour') == true`,
              },
              {
                name: "metallic",
                friendlyName: "Add metallic effect?",
                type: "boolean",
                defaultValue: false,
                showIf: `options.get('hasColour') == true`,
              },
              {
                name: "newTab",
                friendlyName: "Open in New Tab?",
                type: "boolean",
                defaultValue: false,
              }

            ],
          },
        ],
      },
    ],
  },
] as const;

const HeaderBlock = {
  component: Header,
  name: "Header",
  noWrap: true,
  inputs,
};

export type HeaderFields = BuilderFields<GenerateItems<typeof inputs>>;

export default HeaderBlock;
