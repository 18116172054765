import Banner from "./Banner";
import { enumAspectRatio } from "~/utils/builder/enums";

const inputs = [
  {
    name: "image",
    friendlyName: "Banner Image",
    type: "file",
    allowedFileTypes: ["svg", "png", "jpeg", "jpg"],
    required: true,
  },
  {
    name: "aspectRatioLg",
    friendlyName: "Aspect Ratio - Large",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "7:3",
    helpertext: "This aspect ratio will show on tablet and desktop",
    onChange: (options: any, parent: any) => {
      options.set("aspectRatioSm", options.get("aspectRatioLg"));
    },
  },
  {
    name: "aspectRatioSm",
    friendlyName: "Aspect Ratio - Small",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "7:3",
    helpertext: "This aspect ratio will show on mobile",
  },
  {
    name: "bgPos",
    friendlyName: "Image Position",
    type: "string",
    enum: [
      "Center",
      "Top",
      "Bottom",
      "Left",
      "Right",
      "Top-Right",
      "Top-Left",
      "Bottom-Right",
      "Bottom-Left",
    ],
    defaultValue: "Center",
    helperText:
      "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio.",
  },
  {
    name: "imageAlt",
    friendlyName: "Banner Image Alt Text",
    type: "string",
    helperText: "Description of the banner image for accessibility and SEO",
  },
  {
    name: "headerType",
    friendlyName: "Header Type",
    type: "string",
    enum: ["Text", "Image"],
    defaultValue: "Text",
    helperText: "Choose text headings or a logo / image",
  },
  {
    name: "imageHeader",
    friendlyName: "Upload Image",
    type: "file",
    allowedFileTypes: ["svg", "png"],
    helperText:
      "Upload a png or svg with transparent background, with content either black or white",
    showIf: `options.get('headerType') === 'Image'`,
  },
  {
    name: "imageHeaderAlt",
    friendlyName: "Alt Text for Image Header",
    type: "string",
    helperText: "Description of the header image for accessibility and SEO",
    showIf: `options.get('headerType') === 'Image'`,
  },
  {
    name: "header",
    friendlyName: "Main Header Text",
    type: "string",
    showIf: `options.get('headerType') === 'Text'`,
  },
  {
    name: "preHeader",
    friendlyName: "Pre-Header Text",
    type: "string",
    helperText: "This will appear above the Main Header Text",
    showIf: `options.get('headerType') === 'Text'`,
  },
  {
    name: "subHeader",
    friendlyName: "Sub-Header Text",
    type: "string",
    helperText: "This will appear below the Main Header Text",
    showIf: `options.get('headerType') === 'Text'`,
  },
  {
    name: "copyWidth",
    friendlyName: "Limit Text Width",
    type: "string",
    enum: ["None", "Small", "Medium", "Large"],
    defaultValue: "None",
  },
  {
    name: "filterBg",
    friendlyName: "Darken / Lighten Banner?",
    type: "string",
    enum: ["None", "Darken", "Lighten"],
    defaultValue: "None",
    helperText:
      "You can darken or lighten the banner so text appears more clearly",
  },
  {
    name: "textColor",
    friendlyName: "Text Color",
    type: "string",
    enum: ["Black", "White"],
    defaultValue: "White",
    helperText:
      "Choose a text colour that provides contrast with the banner image",
    showIf: `options.get('headerType') === 'Text'`,
  },
  {
    name: "layout",
    friendlyName: "Banner Layout",
    type: "string",
    enum: ["Fullbleed", "Two-Thirds"],
    defaultValue: "Fullbleed",
    helperText: "Choose between a fullbleed layout, or 2/3 image and 1/3 Text",
  },
] as const;

export type BannerFields = BuilderFields<GenerateItems<typeof inputs>>;

const BannerBlock = {
  component: Banner,
  name: "Banner",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fc89aede56c1147128b22cd52fa7646f4",
  inputs,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fb6f94ba1c7954598b40e189813fd7d84",
};

export default BannerBlock;
