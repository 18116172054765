import HeaderWithContent from "./HeaderWithContent";

const inputs = [
  {
    name: "heading",
    friendlyName: "Heading Text",
    helperText: "This heading should be fairly short",
    type: "string",
    required: true,
  },
  {
    name: "bodyContent",
    friendlyName: "Body Text Content",
    type: "richText",
    required: true,
  },
  {
    name: "urlText",
    friendlyName: "URL Display Text",
    helperText:
      "Enter the text that will appear as a link at the bottom of this block",
    type: "string",
    defaultValue: "**LINK TEXT - replace or remove before publishing",
  },
  {
    name: "urlAddress",
    friendlyName: "URL Address",
    helperText: "Enter a valid URL or clear this field",
    type: "string",
    defaultValue: "https://www.romamoulding.com",
    //! is it possible to restrtict the user to only link to interal pages with relative paths, or give them a dropdown of existing pages?
  },
  {
    name: "openNewTab",
    friendlyName: "Open in New Tab?",
    type: "boolean",
    defaultValue: false,
    helperText: "If linking to a site outside the organization, it's best practice to open it in new tab"
  },
  {
    name: "isColumn",
    friendlyName: "Column Layout",
    helperText:
      "By default, the heading and body will be split left/right. Toggle for column layout",
    type: "boolean",
    defaultValue: false,
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: false,
    helperText: "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab"
  }
] as const;

export type HeaderWithContentFields = BuilderFields<
  GenerateItems<typeof inputs>
>;

const HeaderWithContentBlock = {
  component: HeaderWithContent,
  name: "HeaderWithContent",
  friendlyName: "Header With Content",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F5c28d7b89d684d82b11a7fc88fce079a",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fc0258bc227b2490bbc54fd11b6087771",
  inputs,
};

export default HeaderWithContentBlock;
