import {
  Component,
  Show,
  Suspense,
  For,
  ErrorBoundary,
  createEffect,
} from "solid-js";
import { ProductSliderFields } from "./ProductSlider.block";
import { MOULDING, PHOTOFRAME, GALLERYFRAME, MIRROR } from "~/utils/products";
import { BaseProductSlider, Carousel } from "./ui";
import { useProducts } from "~/services/products/useProducts";
import { createAsync } from "@solidjs/router";
import { ProductFilters } from "~/services/roma-api/products/types";

const PRODUCT_TYPES: Record<string, string> = {
  Moulding: MOULDING,
  PhotoFrame: PHOTOFRAME,
  GalleryFrame: GALLERYFRAME,
  Mirror: MIRROR,
};

const ProductSlider: Component<ProductSliderFields> = (props) => {
  const products = createAsync(async () => {
    const obj: ProductFilters = { limit: props.limit ?? 15 };

    if (props.contentType === "List of SKUs" && props.productSkus) {
      obj.skus = props.productSkus.map((prod) => prod.sku);
    }

    // TODO:
    // list of categories/collections is not well structured - that needs to be reworked.

    const prods = await useProducts(obj);

    // transform returned products to match ProductSlider required structure...
    const dictionary = prods.Results.reduce((memo: any, item: any) => {
      if (memo[item.SKU]) return memo;
      memo[item.SKU] = item;
      return memo;
    }, {});

    return props?.productSkus?.reduce((memo: any, item: any) => {
      if (dictionary[item.sku]) {
        const obj = {
          type: PRODUCT_TYPES[item.type],
          data: dictionary[item.sku],
        };
        memo.push(obj);
      }
      return memo;
    }, []);
  });

  const SkeletonSlider = () => {
    const arr = new Array(6);
    return (
      <div aria-hidden="true">
        <Carousel>
          <For each={arr}>
            {() => (
              <div class="flex flex-col col-span-2 group snap-start pl-2 pr-2 first:ml-0 last:mr-0 shrink-0  w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
                <div class="bg-roma-grey aspect-square flex items-center justify-center">
                  <svg
                    class="text-roma-medium-grey animate-pulse"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 39.9995V0H40V40H0V39.9995ZM36.5427 36.5421V3.45554H3.45729V36.5421H36.5427Z"
                      fill="currentColor"
                    />
                    <path
                      d="M9.08203 30.9174V9.08398H30.9142V30.9174H9.08203ZM27.4536 27.4562V12.5414H12.5421V27.4567H27.4536V27.4562Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div class="bg-roma-grey mt-5 h-6  w-10" />
                <p class="bg-roma-grey w-20 h-[22px] mt-1" />
                <p class="bg-roma-grey h-5 mt-1  w-[70%] text-sm" />
              </div>
            )}
          </For>
        </Carousel>
      </div>
    );
  };

  return (
    // TODO
    // <ErrorBoundary
    //   fallback={(err) => {
    //     console.log("TYPEOF ERROR.....", typeof err);
    //     console.log("ERROR OBJ.....", JSON.stringify(err));
    //     console.log("ERROR NAME >>> ", err.name)

    //     console.log("ERROR....", err);
    //     return (
    //       <>
    //         <p>error.message: {err.message}</p>
    //         <p>error.toString(): {err.toString()}</p>
    //         <pre>Error JSON: {JSON.stringify(err)}</pre>
    //       </>
    //     );
    //   }}
    // >
      <Suspense>
        <Show when={products()} fallback={<SkeletonSlider />}>
          <BaseProductSlider
            title={props.title}
            href={props.href}
            hrefLabel={props.hrefLabel}
            productList={products()}
          />
        </Show>
      </Suspense>
    // </ErrorBoundary>
  );
};

export default ProductSlider;
