import { enumContentWidth } from "~/utils/builder/enums";
import OpenPositions from "./OpenPositions";

const inputs = [
  {
    name: "sectionTitle",
    friendlyName: "Section Title",
    type: "string",
  },
  {
    name: "bodyContent",
    friendlyName: "Description / Text Content",
    helperText: "This text will appear below the section title",
    type: "longText",
  },
  {
    name: "contentWidth",
    friendlyName: "Text Content Width",
    type: "string",
    enum: enumContentWidth,
    defaultValue: "Full",
    required: true,
    helperText: "Use if the content needs to be constrained"
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: false,
    helperText: "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab"
  },
  {
    name: "sectionId",
    friendlyName: "Section ID",
    type: "string",
    helperText: "This is used to link to this specific part of the page - no spaces, use hyphens instead"
  }
] as const;

export type OpenPositionsFields = BuilderFields<GenerateItems<typeof inputs>>;

const OpenPositionsBlock = {
  component: OpenPositions,
  name: "OpenPositions",
  friendlyName: "Open Positions",
  section: "Content Components",
  inputs,
};

export default OpenPositionsBlock;
