import ProductSlider from "./ProductSlider";

const inputs = [
  {
    type: "string",
    name: "title",
  },
  {
    name: "contentType",
    friendlyName: "Content Type",
    type: "string",
    enum: ['List of SKUs', 'List of Collections', 'List of Categories'],
    defaultValue: "List of SKUs",
  },
  {
    name: "productSkus",
    friendlyName: "SKU",
    type: "list",
    showIf: `options.get('contentType') === 'List of SKUs'`,
    subFields: [
      {
        name: "sku",
        type: "string",
        required: true,
      },
      {
        name: "type",
        type: "string",
        enum: ["Moulding", "PhotoFrame", "GalleryFrame", "Mirror"],
        defaultValue: "Moulding",
        required: true,
      },
    ]
  },
  {
    name: "collection",
    type: "list",
    friendlyName: "WIP - Do not use",
    showIf: `options.get('contentType') === 'List of Collections'`,
    subFields: [
      {
        name: "collectionName",
        friendlyName: "Collection Name",
        type: "reference",
        model: "collection"
      },
      {
        name: "type",
        type: "string",
        enum: ["Moulding", "PhotoFrame", "GalleryFrame", "Mirror"],
        defaultValue: "Moulding",
      },
    ]
  },
  {
    name: "category",
    friendlyName: "WIP - Do not use",
    type: "list",
    showIf: `options.get('contentType') === 'List of Categories'`,
    subFields: [
      {
        name: "categoryName",
        friendlyName: "Category Name",
        type: "reference",
        model: "category"
      },
      {
        name: "type",
        type: "string",
        enum: ["Moulding", "PhotoFrame", "GalleryFrame", "Mirror"],
        defaultValue: "Moulding",
      },
    ]
  },
  {
    name: "limit",
    type: "number",
    defaultValue: 10,
  },
  {
    name: "sortBy",
    friendlyName: "Sort By (Not functioning yet) - don't use",
    type: "string",
    enum: ["Default", "A", "B", "C"],
    defaultValue: "Default",
  },
  {
    name: "href",
    friendlyName: "Link",
    type: "string",
  },
  {
    name: "hrefLabel",
    friendlyName: "Link Label",
    defaultValue: "View All"
  },
  {
    friendlyName: "Items ** Being removed - don't use",
    type: "list",
    name: "list",
    subFields: [
      {
        friendlyName: "SKU",
        type: "string",
        name: "sku",
        required: true,
      },
      {
        type: "string",
        name: "title",
        required: true,
      },
      {
        type: "string",
        name: "width",
      },
      {
        type: "string",
        name: "height",
      },
      {
        type: "string",
        name: "rabbet",
      },
      {
        type: "url",
        name: "url",
      },
    ],
  },
] as const;

export const ProductSliderBlock = {
  component: ProductSlider,
  name: "ProductSlider",
  friendlyName: "Product Slider",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F84dc1dbb0be94446af7362bf70d441ed",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F12d0c1d4a54d4767b4ce17062ca81e60",
  inputs,
};

export type ProductSliderFields = BuilderFields<GenerateItems<typeof inputs>>;

export default ProductSliderBlock;
