import TextCopyWithButton from "./TextCopyWithButton";

const inputs = [
  {
    name: "header",
    type: "string",
  },
  {
    name: "copy",
    type: "longText",
  },
  {
    name: "isButton",
    friendlyName: "Add Button?",
    type: "boolean",
    defaultValue: true
  },
  {
    name: "buttonText",
    type: "string",
    showIf: 'options.get("isButton") == true'
  },
  {
    name: "buttonHref",
    friendlyName: "Button Link",
    type: "string",
    showIf: 'options.get("isButton") == true'
  },
  {
    name: "buttonColour",
    type: "string",
    enum: ["Black", "White"],
    defaultValue: "Black",
    showIf: 'options.get("isButton") == true'
  },
  {
    name: "textWidth",
    type: "string",
    enum: ["Fullbleed", "Contained"]
  }
] as const;

export type TextCopyWithButtonFields = BuilderFields<GenerateItems<typeof inputs>>;

const TextCopyWithButtonBlock = {
  component: TextCopyWithButton,
  name: "TextCopyWithButton",
  friendlyName: "Text Copy With Button",
  section: "Content Components",
  inputs,
};

export default TextCopyWithButtonBlock

