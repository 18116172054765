import {
  Component,
  mergeProps,
} from "solid-js";
import { CardFeatureFields } from "./CardFeature.block";
import placeholderSq from "~/assets/placeholderSq.png";
import Feature from "./Feature";

const CardFeature: Component<CardFeatureFields> = (inProps) => {
  const props = mergeProps(
    {
      ratio: "1/1",
      image: placeholderSq,
      buttons: [],
      upperTitle: "",
      title: "Title Text",
      bgPosition: "Center",
      aspectRatioLg: "4:3",
      aspectRatioSm: "4:3",
      bgFilter: "None",
      hasUpperImage: "false",
      titleSize: "Large",
    },
    inProps
  );

  return (
    <>
      <Feature
        bannerImage={props.image}
        bannerAlt={props.imageAlt}
        bgPosition={props.bgPosition}
        aspectRatioLg={props.aspectRatioLg}
        aspectRatioSm={props.aspectRatioSm}
        hasUpperImage={props.hasUpperImage}
        upperTitle={props.upperTitle}
        upperImage={props.upperImage}
        upperImageAlt={props.upperImageAlt}
        title={props.title}
        titleSize={props.titleSize}
        bgFilter={props.bgFilter}
        textColour={props.textColour}
        copy={props.copy}
        buttons={[...props.buttons]}
        buttonPosition={props.buttonPosition}
      />
    </>
  );
};

export default CardFeature;
