import {
  FlowComponent,
  Show,
  Accessor,
  createEffect,
} from "solid-js";
import { Motion, Presence } from "solid-motionone";

const ModalWindow: FlowComponent<{
  onClose: VoidFunction;
  class?: string;
  size?: "Large";
  blur?: "sm" | "md";
  exitDuration?: number;
  open: Accessor<boolean>;
}> = (props) => {
  const setOverflow = (value = "hidden") => {
    if (window.document.body.style.overflow != value) {
      window.document.body.style.overflow = value;
    }
  };

  createEffect(() => setOverflow(props.open() ? "hidden" : "auto"));

  // const {
  //   headerVisible: [header, _, setHeaderVisible],
  // } = useContext(SiteContext);

  // createEffect(() => {
  //   if (props.open()) {
  //     setHeaderVisible(false);
  //   } else {
  //     setHeaderVisible(true);
  //   }
  // });

  return (
    <Presence exitBeforeEnter>
      <Show when={props.open()}>
        <Motion.div
          exit={{
            opacity: 0,
            transition: { duration: props.exitDuration ?? 0.7 },
          }}
          transition={{ duration: 1, easing: "ease-in-out" }}
        >
          <div
            class="relative z-30"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="fixed inset-0  transition-opacity backdrop"
              classList={{
                "backdrop-blur-lg bg-white bg-opacity-75": !props.blur,
                "backdrop-blur-sm": props.blur === "sm",
                "backdrop-blur-md": props.blur === "md",
              }}
            />
            <div class="fixed inset-0 z-10 overflow-y-auto">
              <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                  class="absolute w-screen h-screen"
                  onClick={()=>props.onClose()}
                />
                <Presence exitBeforeEnter>
                  <Show when={props.open()}>
                    <Motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{
                        opacity: 0,
                        scale: 0.9,
                        transition: { duration: 0.4 },
                      }}
                      transition={{ duration: 0.4, easing: "ease-in-out" }}
                    >
                      <div
                        class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full  sm:p-6"
                        classList={{
                          [`${props.class}`]: !!props.class,
                          "sm:max-w-lg": !props.size,
                          "sm:w-[80vw]": props.size === "Large",
                        }}
                      >
                        <div class="absolute top-0 right-0  pt-4 pr-4 block">
                          <button
                            aria-label="Close"
                            onClick={()=>props.onClose()}
                            type="button"
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            <span class="sr-only">Close</span>
                            <svg
                              class="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        <div class="p-4">
                          {props.children}
                        </div>
                      </div>
                    </Motion.div>
                  </Show>
                </Presence>
              </div>
            </div>
          </div>
        </Motion.div>
      </Show>
    </Presence>
  );
};

export default ModalWindow;
