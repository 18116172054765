import CallToAction from "./CallToAction";
import {
  enumIcons,
  enumContentWidth,
} from "~/utils/builder/enums";

const inputs = [
  {
    name: "list",
    friendlyName: "CTA",
    type: "list",
    required: true,
    onChange: (options: any, parent: any) => {
      if (options.get("list").length > 3) {
        options.set("list", options.get("list").slice(0, 3));
        alert("maximum items is 3, delete items to continue");
      }},
    subFields: [
      {
        name: "type",
        type: "string",
        enum: ["Newsletter", "Action"],
      },
      {
        name: "mailchimpListID",
        friendlyName: "MailChimp List ID",
        type: "string",
        required: true,
        helperText:
          'Mailchimp list ID is available inside the "Settings" tab within the "Audience" section',
        showIf: `options.get('type') === 'Newsletter'`,
      },
      {
        name: "actionType",
        type: "string",
        enum: ["Link","Phone", "Email", "Chat", "Find A Retailer"],
        defaultValue: "Email",
        showIf: `options.get('type') === 'Action'`,
      },
      {
        name: "actionValue",
        type: "string",
        helperText: "Enter a link, phone number with country code, or email.",
        showIf: `options.get('actionType') === 'Phone' || options.get('actionType') === 'Email' || options.get('actionType') === 'Link'`,
      },
      {
        name: "title",
        type: "string",
        defaultValue: "title",
      },
      {
        name: "headingLevel",
        friendlyName: "Heading Level",
        type: "string",
        enum: ["h1", "h2", "h3", "h4", "p"],
        default: "h2",
      },
      {
        name: "copy",
        friendlyName: "Text Content",
        type: "richText",
      },
      {
        name: "icon",
        type: "string",
        enum: enumIcons,
      },
      {
        name: "buttonText",
        friendlyName: "Button Text",
        type: "string",
        defaultValue: "Sign up",
      },
      {
        name: "placeholderText",
        friendlyName: "Placeholder Text",
        type: "string",
        defaultValue: "Enter your email",
        showIf: `options.get('type') === 'Newsletter'`,
      },
      {
        name: "submissionMessage",
        friendlyName: "Post Submission Message",
        type: "longText",
        defaultValue: "Thanks for siging up!",
        helperText: "This text will show after a successfull email submission",
        showIf: `options.get('type') === 'Newsletter'`,
      },
      {
        name: "contentWidth",
        friendlyName: "Limit Text Width",
        type: "string",
        enum: enumContentWidth,
        helperText: "Should only be used if CTA is being used with a single element, if multiple elements leave blank or at 'Full'"
      },
    ],
    defaultValue: [{
      title: "Title",
      type: "Newsletter",
      icon: "Roma Logo",
      copy: "Text content goes here",
      buttonText: "Sign Up",

    }]
  },
] as const;

export type CallToActionFields = BuilderFields<GenerateItems<typeof inputs>>;

const CallToActionBlock = {
  component: CallToAction,
  name: "CallToAction",
  friendlyName: "Call To Action",
  section: "Interactive Components",
  inputs,
};

export default CallToActionBlock;
