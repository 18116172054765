import Feature from "./Feature";
import { enumAspectRatio, enumFilter, enumBgPosition } from "~/utils/builder/enums";

const inputs = [
  {
    name: "bannerImage",
    friendlyName: "Background Image",
    type: "file",
    allowedFileTypes: ["jpg","jpeg","png","svg"],
  },
  {
    name: "bannerAlt",
    friendlyName: "Image Alt Text",
    type: "string",
  },
  {
    name: "aspectRatioLg",
    friendlyName: "Aspect Ratio - Large",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "4:3",
    helpertext: "This aspect ratio will show on tablet and desktop",
    onChange: (options: any, parent: any) => {
      options.set("aspectRatioSm", options.get("aspectRatioLg"))
    }
  },
  {
    name: "aspectRatioSm",
    friendlyName: "Aspect Ratio - Small",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "4:3",
    helpertext: "This aspect ratio will show on mobile",
  },
  {
    name: "bgPosition",
    friendlyName: "Background Position",
    type: "string",
    enum: enumBgPosition,
    defaultValue: "Center",
    helperText: "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio."
  },
  {
    name: "bgFilter",
    friendlyName: "Darken / Lighten Image?",
    type: "string",
    enum: enumFilter,
    required: true,
    defaultValue: "None",
    helperText: "Darken or Lighten the image so that the text is more legible - it will darken further on hover",
  },
  {
    name: "hasUpperImage",
    friendlyName: "Show Image Above Header?",
    type: "boolean",
    defaultValue: false,
  },
  {
    name: "upperImage",
    friendlyName: "Upper Image",
    type: "file",
    allowedFileTypes: ["jpg","jpeg","png","svg"],
    showIf: "options.get('hasUpperImage') === true",
  },
  {
    name: "upperImageAlt",
    friendlyName: "Upper Image Alt Text",
    type: "string",
    showIf: "options.get('hasUpperImage') === true",
  },
  {
    name: "textColour",
    friendlyName: "Text Color",
    type: "string",
    enum: ["White", "Black"],
    defaultValue: "White"
  },
  {
    name: "upperTitle",
    friendlyName: "Upper Title",
    type: "string",
    showIf: "options.get('hasUpperImage') === false",
  },
  {
    name: "title",
    friendlyName: "Title",
    type: "string",
    defaultValue: "Title Text",
  },
  {
    name: "titleSize",
    friendlyName: "Title Size",
    type: "string",
    enum: ["Small", "Medium", "Large"],
    defaultValue: "Large",
    helperText: "Text may appear to be too large in Builder Editor, preview the live page for a better representation"
  },
  {
    name: "copy",
    friendlyName: "Additional Text Copy",
    type: "string",
    helperText: "Optional - content here will show when the user hovers over the card on desktop, and below the card on smaller screens. Content should be kept short - preview the page to ensure text is not colliding with title"
  },
  {
    name: "buttons",
    type: "list",
    subFields: [
      {
        name: "label",
        type: "string",
        defaultValue: "Learn More",
      },
      {
        name: "url",
        type: "string",
        defaultValue: "/"
      }
    ],
    defaultValue: [{
      label: "Learn More",
      url: "/"
    }]
  },
  {
    name: "buttonPosition",
    type: "string",
    enum: ["Bottom", "Center"],
    defaultValue: "Bottom",
  }
] as const;

export type FeatureFields = BuilderFields<GenerateItems<typeof inputs>>;

const FeatureBlock = {
  component: Feature,
  name: "Feature",
  section: "Content Components",
  inputs,
}

export default FeatureBlock;

