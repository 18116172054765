import { Component, mergeProps, For, Show } from "solid-js";
import Button from "./Button";
import { RomaTourFields } from "./RomaTour.block";
import { iconPath } from "~/utils/icons";
import { Img } from "~/components/image";

const RomaTour: Component<RomaTourFields> = (inProps) => {
  const props = mergeProps(
    {
      sectionTitle: "Choose from three tour packages",
      tours: [
        {
          title: "Roma Tour",
          icon: "Tour 1 Star",
          bestValue: false,
          price: "Free",
          duration: "60 minutes",
          features: [
            { title: "Headquarters tour" },
            { title: "Q&A Session:,A Department Leader of Choice" },
          ],
          buttonText: "Register",
          buttonStyle: "Outline",
          buttonHref: "#",
        },
        {
          title: "WOW Tour",
          icon: "Tour 3 Star",
          bestValue: true,
          price: "$95 per person ",
          duration: "120 minutes",
          features: [
            { title: "Headquarters tour" },
            {
              title:
                "Q&A Session:,Roma's Chief of WOW Tony Gareri,A Department Leader of Choice",
            },
            { title: "A customized WOW Package" },
            { title: "Culture Presenation" },
          ],
          buttonText: "Register",
          buttonStyle: "Solid",
          buttonHref: "#",
        },
        {
          title: "Inspired Tour",
          icon: "Tour 2 Star",
          bestValue: false,
          price: "$50 per person ",
          duration: "90 minutes",
          features: [
            { title: "Headquarters tour" },
            {
              title:
                "Q&A Session:,Roma's Chief of WOW Tony Gareri,A Department Leader of Choice",
            },
            { title: "A customized WOW Package" },
          ],
          buttonText: "Register",
          buttonStyle: "Outline",
          buttonHref: "#",
        },
      ],
    },
    inProps
  );

  return (
    <div
      id={props.sectionId ? props.sectionId : undefined}
      class="w-full flex flex-col items-center text-center"
    >
      <h3 class="text-xl font-bold">{props.sectionTitle}</h3>
      <section class="w-full py-20 grid md:grid-cols-3 child:px-12 child:py-12 child:md:py-0 md:divide-x divide-y md:divide-y-0">
        <For each={props.tours}>
          {(item) => (
            <div class="flex flex-col justify-between items-center group text-center">
              <div class="flex flex-col items-center gap-2 text-sm font-medium pb-8">
                <Show
                  when={item.bestValue}
                  fallback={
                    <>
                      <div class="w-10">
                        <Img
                          src={iconPath[item.icon as string]}
                          layout="constrained"
                          width={40}
                          aspectRatio={2}
                          alt=""
                        />
                      </div>
                      <div class="text-center">
                        <h3 class="text-2xl pb-6 font-bold">{item.title}</h3>
                      </div>
                    </>
                  }
                >
                  <div class="w-10 relative transition-transform duration-700 sm:group-hover:-translate-y-5">
                    <Img
                      src={iconPath[item.icon as string]}
                      layout="constrained"
                      width={40}
                      aspectRatio={2}
                      alt=""
                    />
                  </div>
                  <div class="text-center relative transition-transform duration-700 sm:group-hover:-translate-y-5">
                    <h3 class="text-2xl font-bold">{item.title}</h3>
                    <p class="text-xs text-roma-blue font-medium">Best value</p>
                  </div>
                </Show>
                <p class="text-center text-lg">
                  {item.price} <span class="font-normal">{item.duration}</span>
                </p>

                <For each={item.features}>
                  {(item) => (
                    <>
                      <span class="text-roma-dark-grey">&bull;</span>
                      <div class="text-center text-lg">
                        <For each={item.title?.split(",")}>
                          {(item) => <p>{item}</p>}
                        </For>
                      </div>
                    </>
                  )}
                </For>
              </div>
              <div class="w-full">
                <Button
                  style={item.buttonStyle!.toLowerCase()}
                  class="w-full"
                  href={item.buttonHref}
                  target="_blank"
                >
                  {item.buttonText}
                </Button>
              </div>
            </div>
          )}
        </For>
      </section>
    </div>
  );
};

export default RomaTour;
