import { Component, mergeProps, Show, For, createMemo } from "solid-js";
import { FeatureFields } from "./Feature.block";
import { A } from "@solidjs/router";
import { builderImage } from "~/utils/builder";
import placeholder from "~/assets/placeholder.png";
import { Icon } from "solid-heroicons";
import { play, chevronRight } from "solid-heroicons/outline";
import { aspectRatio, bgPosition, bgFilter } from "~/utils/builder/enums";
import { Img } from "~/components/image";

export type FeatureType = {
  bannerImage: string;
  bannerAlt?: string;
  bgPosition: string;
  aspectRatioLg: string;
  aspectRatioMd?: string;
  aspectRatioSm?: string;
  hasUpperImage?: boolean;
  upperTitle?: string;
  upperImage?: string;
  upperImageAlt?: string;
  title: string;
  titleSize?: string;
  bgFilter?: string;
  textColour?: string;
  copy?: string;
  buttons?: {
    label: string;
    showIcon?: string;
    url: string;
  }[];
  buttonPosition: string;
  showPlayButton?: boolean;
  onPlayClick?: VoidFunction;
  playButtonText?: string;
};

const Feature: Component<FeatureFields & FeatureType> = (inProps) => {
  const props = mergeProps(
    {
      bannerImage: placeholder,
      bgPosition: "Center",
      aspectRatioLg: "4:3",
      aspectRatioSm: "4:3",
      hasUpperImage: false,
      title: "Title Text",
      titleSize: "Large",
      textColour: "White",
      buttons: [],
      buttonPosition: "Bottom",
      showPlayButton: false,
      onPlayClick: () => {},
      playButtonText: "Watch the Film",
    },
    inProps
  );

  const titleSize = createMemo(() => {
    switch (props.titleSize) {
      case "Small":
        return "text-xl sm:text-2xl";
      case "Medium":
        return "text-2xl sm:text-3xl";
      case "Large":
        return "text-3xl sm:text-5xl";
      default:
        return "text-3xl sm:text-5xl";
    }
  });

  const text: Record<string, string> = {
    White: "text-white",
    Black: "text-black",
  };

  return (
    <div
      class="relative group"
      classList={{
        [text[props.textColour]]: !!props.textColour,
      }}
    >
      <div
        class="w-full flex flex-col justify-center  relative overflow-hidden  duration-500 delay-[200ms]"
        classList={{
          [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
          [`sm:${aspectRatio[props.aspectRatioLg]}`]: !!props.aspectRatioLg,
        }}
      >
        <div class="absolute inset-0 md:group-hover:bg-black/50 transition-colors duration-500 delay-[200ms]" />
        <div
          class="w-full scale-100 md:group-hover:scale-110 transition-transform duration-500 delay-[200ms] bg-cover absolute inset-0 -z-10 "
          classList={{
            [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
            [`sm:${aspectRatio[props.aspectRatioLg]}`]: !!props.aspectRatioLg,
            [bgPosition[props.bgPosition]]: !!props.bgPosition,
            [`${bgFilter[props.bgFilter]}`]: !!props.bgFilter,
          }}
          //!Note: fit:cover does not seem to work, test by removing 'bg-cover' from classes */
          style={{
            "background-image": `url(${builderImage({
              src: props.bannerImage,
              fit: "cover",
              quality: 75,
            })})`,
          }}
        />
        <div class="mx-auto flex flex-col items-center justify-center text-center w-full h-full z-10 ">
          <div
            class="flex flex-col gap-3"
            classList={{
              "md:translate-y-0 md:group-hover:-translate-y-4 transition-transform duration-500 delay-[200ms]":
                !!props.copy,
            }}
          >
            <Show when={props.upperTitle && !props.hasUpperImage}>
              <p class="sm:text-lg font-bold px-2">{props.upperTitle}</p>
            </Show>
            <Show when={props.hasUpperImage && props.upperImage}>
              <Img
                src={props.upperImage as string}
                width={300}
                height={300}
                layout="constrained"
                alt=""
              />
            </Show>
            <h3
              class="font-bold max-w-3xl px-2"
              classList={{
                [titleSize()]: true,
              }}
            >
              {props.title}
            </h3>
            <Show when={props.buttonPosition === "Center"}>
              <div class="bottom-0 flex justify-center gap-8 z-30">
                <Show when={props.showPlayButton}>
                  <button
                    aria-label={props.playButtonText}
                    class="inline-flex gap-1 items-center"
                    onClick={() => props.onPlayClick()}
                  >
                    <div class="inline underline-hover">
                      {props.playButtonText}
                    </div>
                    <div>
                      <Icon path={play} class="w-4 mb-1" />
                    </div>
                  </button>
                </Show>
                <For each={props.buttons}>
                  {(button) => (
                    <A
                      href={button.url}
                      class="inline-flex gap-1 items-center"
                      // classList={{[text[props.textColour]]: !!props.textColour }}
                    >
                      <div class="inline underline-hover">{button.label}</div>
                      <div>
                        <Icon path={chevronRight} class="w-4 mb-1" />
                      </div>
                    </A>
                  )}
                </For>
              </div>
            </Show>
            <Show when={props.copy && props.buttonPosition === "Center"}>
              <div class="flex justify-center w-full max-w-2xl text-center  text-sm md:text-lg md:opacity-0 md:group-hover:opacity-100   transition-all duration-500 delay-[200ms]">
                <p class="px-5">{props.copy}</p>
              </div>
            </Show>
          </div>
        </div>
      </div>
      <Show when={props.buttonPosition === "Bottom"}>
        <div class="text-black mt-3 md:text-white md:absolute bottom-6 flex flex-col gap-5 w-full z-30">
          <Show when={props.copy}>
            <div class="text-center flex justify-center w-full text-sm md:text-lg md:opacity-0 md:group-hover:opacity-100 translate-y-0 md:group-hover:-translate-y-12 md:absolute bottom-0 transition-all duration-500 delay-[200ms]">
              <p class="px-5 max-w-xl">{props.copy}</p>
            </div>
          </Show>
          <div class="flex justify-center gap-8 w-full">
            <Show when={props.showPlayButton}>
              <button
                aria-label={props.playButtonText}
                class="inline-flex gap-1"
                onClick={()=>props.onPlayClick()}
              >
                <div class="inline underline-hover">{props.playButtonText}</div>
                <div>
                  <Icon path={play} class="w-4 mb-1" />
                </div>
              </button>
            </Show>
            <For each={props.buttons}>
              {(button) => (
                <A href={button.url} class="inline-flex gap-1">
                  <div class="inline underline-hover">{button.label}</div>
                  <div>
                    <Icon path={chevronRight} class="w-4 mb-1" />
                  </div>
                </A>
              )}
            </For>
          </div>
        </div>
      </Show>
    </div>
  );
};

export default Feature;
