import { Component, Show, mergeProps } from "solid-js";
import { TextCopyWithButtonFields } from "./TextCopyWithButton.block";
import Button from "./Button";

const TextCopyWithButton: Component<TextCopyWithButtonFields> = (inProps) => {
  const props = mergeProps(
    {
      header: "Heading goes here",
      copy: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique cupiditate porro eveniet expedita, earum quam repellat libero qui in laborum doloremque vitae repudiandae eligendi recusandae adipisci deserunt ipsum sequi reiciendis.",
      buttonText: "Button Text",
    },
    inProps
  );

  return (
    <div
      class="flex flex-col justify-center items-center gap-6 text-center mx-auto px-3 "
      classList={{ "max-w-3xl": props.textWidth === "Contained" }}
    >
      <h2 class="text-2xl sm:text-4xl font-bold">{props.header}</h2>
      <p class="text-base sm:text-xl leading-7">{props.copy}</p>
      <Show when={props.isButton}>
        <div>
          <Button
            class="text-sm sm:text-base font-medium"
            style={props.buttonColour === "Black" ? "solid" : "outline"}
            href={props.buttonHref}
          >
            <span class="px-4">{props.buttonText}</span>
          </Button>
        </div>
      </Show>
    </div>
  );
};

export default TextCopyWithButton;
