import AllHandsArchive from "./AllHandsArchive";

const inputs = [
  {
    name: "sectionTitle",
    type: "string",
    friendlyName: "Section Title",
  },
] as const;

export type AllHandsArchiveFields = BuilderFields<GenerateItems<typeof inputs>>;

const AllHandsArchiveBlock = {
  component: AllHandsArchive,
  name: "AllHandsArchive",
  friendlyName: "All Hands Archive",
  section: "Content Components",
  inputs,
};

export default AllHandsArchiveBlock;
