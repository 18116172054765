import ContentGrid from "./ContentGrid";
import { enumIcons } from "~/utils/builder/enums";

const inputs = [
  {
    name: "sectionTitle",
    friendlyName: "Section Title",
    type: "string",
    defaultValue: "Section Title",
    helperText: "Optional",
  },
  {
    name: "contentBlock",
    friendlyName: "Content Block",
    type: "list",
    subFields: [
      {
        name: "contentType",
        friendlyName: "Infoblock or Testimonial",
        type: "string",
        enum: ["Infoblock", "Testimonial"],
        defaultValue: "Infoblock",
        required: true,
      },
      {
        name: "isIcon",
        friendlyName: "Icon or Image Upload",
        type: "string",
        enum: ["Icon", "Upload"],
        required: true,
      },
      {
        name: "icon",
        friendlyName: "Choose Icon",
        type: "string",
        enum: enumIcons,
        showIf: 'options.get("isIcon") === "Icon"',
      },
      {
        name: "uploadedImage",
        friendlyName: "Upload an Image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
        showIf: 'options.get("isIcon") === "Upload"',
        helperText: "Image should be square with the face centered",
      },
      {
        name: "roundoverImage",
        friendlyName: "Round Border on Image?",
        type: "boolean",
        default: true,
        showIf: 'options.get("isIcon") === "Upload"',
      },
      {
        name: "title",
        friendlyName: "Title",
        type: "string",
        showIf: 'options.get("contentType") === "Infoblock"',
        // 'options.get("contentType") === "Infoblock"',
      },
      {
        name: "bodyText",
        friendlyName: "Body Text",
        type: "longText",
      },
      {
        name: "quotee",
        friendlyName: "Quotee and Attribution",
        type: "string",
        helperText: "eg. Michelle Hachem, Marketing Directer, Hachem",
        showIf: 'options.get("contentType") === "Testimonial"',
      },
      {
        name: "href",
        friendlyName: "Link",
        type: "string",
        helperText:
          "Optional - for on-page linking (anchor link) start with '#' followed by the ID of the section you'd like to link to. eg. #my-special-section",
      },
    ],
    defaultValue: [
      {
        contentType: "Infoblock",
        isIcon: "Icon",
        icon: "Roma Logo",
        title: "Title",
        bodyText:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe culpa repellendus dolorum enim nesciunt obcaecati, eum aliquam labore impedit asperiores!",
      },
      {
        contentType: "Infoblock",
        isIcon: "Icon",
        icon: "Roma Logo",
        title: "Title",
        bodyText:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe culpa repellendus dolorum enim nesciunt obcaecati, eum aliquam labore impedit asperiores!",
      },
      {
        contentType: "Infoblock",
        isIcon: "Icon",
        icon: "Roma Logo",
        title: "Title",
        bodyText:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe culpa repellendus dolorum enim nesciunt obcaecati, eum aliquam labore impedit asperiores!",
      },
    ],
  },
] as const;

export type ContentGridFields = BuilderFields<GenerateItems<typeof inputs>>;

const ContentGridBlock = {
  component: ContentGrid,
  name: "ContentGrid",
  friendlyName: "Content Grid",
  section: "Layout Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Ffea40649c8f2473aa4a46872669625bc",
  inputs,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fbd0fc96637b64316b682a499994fd48e",
};

export default ContentGridBlock;
