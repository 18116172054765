import { mergeProps, createMemo, FlowComponent } from "solid-js";
import { ImageWithContentFields } from "./ImageWithContent.block";
import placeholder from "~/assets/placeholder.png";
import { builderImage } from "~/utils/builder";
import { Img } from "~/components/image";

const ImageWithContent: FlowComponent<ImageWithContentFields> = (inProps) => {
  const props = mergeProps(
    {
      image: placeholder,
    },
    inProps
  );
  const layout = createMemo(() => {
    switch (props.layout) {
      case "Right":
        return "lg:flex-row";
      case "Left":
        return "lg:flex-row-reverse ";
      case "Full":
        return "lg:flex-col-reverse";
    }
  });
  return (
    <div
      id={props.id ? props.id.trim().replaceAll(" ", "-") : undefined}
      class={`flex flex-col-reverse items-center md:py-8 ${layout()}`}
    >
      <div
        class={`w-full ${props.layout === "Full" ? "lg:w-3/4" : "lg:w-1/2"}`}
      >
        {props.children}
      </div>
      <div
        class={`w-full boundary-outer ${
          props.layout === "Full" ? "aspect-3/1 lg:mb-8" : "aspect-4/3 lg:w-1/2"
        }`}
        classList={{
          "lg:pl-10": props.layout == "Right",
          "lg:pr-10": props.layout == "Left",
        }}
      >
        <Img
          src={builderImage({
            src: props.image,
            width: 900,
            format: "webm",
          })}
          width={900}
          aspectRatio={props.layout === "Full" ? 3 : 1.33}
          alt={props.altText ?? ""}
          class="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default ImageWithContent;
