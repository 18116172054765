// @ts-nocheck
export function launchLiveChat(name, id, callback?) {
  (window.__lc = window.__lc || {}),
    (window.__lc.license = 3997401),
    (function (t, n, c) {
      function e(t) {
        return i._h ? i._h.apply(null, t) : i._q.push(t);
      }
      var i = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          e(["on", c.call(arguments)]);
        },
        off: function () {
          e(["off", c.call(arguments)]);
        },
        get: function () {
          if (!i._h)
            throw Error("[LiveChatWidget] You can't use getters before load.");
          return e(["get", c.call(arguments)]);
        },
        call: function () {
          e(["call", c.call(arguments)]);
        },
        init: function () {
          var t = n.createElement("script");
          (t.async = !0),
            (t.type = "text/javascript"),
            (t.src = "https://cdn.livechatinc.com/tracking.js"),
            n.head.appendChild(t);
        },
      };
      t.__lc.asyncInit || i.init(), (t.LiveChatWidget = t.LiveChatWidget || i);
    })(window, document, [].slice);

  window.LiveChatWidget.on("ready", () => {
    window.LiveChatWidget.call("maximize");
    window.LiveChatWidget.call("set_customer_name", name);
    window.LiveChatWidget.call("set_session_variables", {
      partner_number: id,
    });
    if (callback) {
      callback();
    };
  });
}
