import FeatureVideo from "./FeatureVideo";
import {
  enumFilter,
  enumAspectRatio,
  enumBgPosition,
} from "~/utils/builder/enums";

const inputs = [
  {
    name: "header",
    type: "string",
  },
  {
    name: "subheader",
    friendlyName: "Sub Header",
    type: "string",
  },
  {
    name: "subheaderPosition",
    friendlyName: "*Sub Header Position",
    type: "string",
    enum: ["Above", "Below"],
    defaultValue: "Above",
    helperText: "Being removed - don't use",
  },
  {
    name: "titleSize",
    friendlyName: "Title Size",
    type: "string",
    enum: ["Small", "Medium", "Large"],
    defaultValue: "Large",
  },
  {
    name: "youtubeId",
    friendlyName: "YouTube ID",
    required: true,
    type: "string",
    helperText:
      "The id is found at the end of the url (eg. https://www.youtube.com/watch?v=UNnvYrQcKzI [UNnvYrQcKzI is the id])",
  },
  {
    name: "posterImage",
    friendlyName: "Poster Image",
    helperText:
      "This image will be used before the video is played, with text overlaid, if left blank it will use YouTube's default thumnail image",
    type: "file",
    allowedFileTypes: ["jpeg", "jpg", "png"],
  },
  {
    name: "aspectRatioLg",
    friendlyName: "Aspect Ratio - Large",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "7:3",
    required: true,
    helpertext: "This aspect ratio will show on tablet and desktop",
    onChange: (options: any, parent: any) => {
      options.set("aspectRatioSm", options.get("aspectRatioLg"));
    },
  },
  {
    name: "aspectRatioSm",
    friendlyName: "Aspect Ratio - Small",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "4:3",
    required: true,
    helpertext: "This aspect ratio will show on mobile",
  },
  {
    name: "imageFilter",
    friendlyName: "Darken / Lighten Image?",
    type: "string",
    enum: enumFilter,
    defaultValue: "Darken-1",
    required: true,
  },
  {
    name: "bgPosition",
    friendlyName: "Background Position",
    type: "string",
    enum: enumBgPosition,
    defaultValue: "Center",
    required: true,
    helperText:
      "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio.",
  },
  {
    name: "playText",
    friendlyName: "Play Button Text",
    defaultValue: "Watch The Film",
    type: "string",
  },
  {
    name: "showIcon",
    friendlyName: "Show Icon?",
    type: "boolean",
    defaultValue: true,
  },
  {
    name: "buttons",
    helperText: "Optional - link to an additional resource or page",
    type: "list",
    subFields: [
      {
        name: "label",
        type: "string",
        defaultValue: "Learn More",
      },
      {
        name: "url",
        type: "string",
        defaultValue: "/",
      },
    ],
  },
  {
    name: "secondaryLink",
    friendlyName: "Secondary Link",
    helperText: "Optional - link to an additional resource or page",
    type: "string",
  },
  {
    name: "secondaryButton",
    friendlyName: "Secondary Link Button Text",
    type: "string",
  },
  {
    name: "buttonPosition",
    friendlyName: "Button Position",
    type: "string",
    enum: ["Center", "Bottom"],
    defaultValue: "Center",
    required: true,
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: false,
    helperText:
      "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab",
  },
] as const;

export type FeatureVideoFields = BuilderFields<GenerateItems<typeof inputs>>;

const FeatureVideoBlock = {
  component: FeatureVideo,
  name: "FeatureVideo",
  friendlyName: "Feature Video",
  section: "Content Components",
  inputs,
};

export default FeatureVideoBlock;
