import { Component, For, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";
import { MasonryGridFields } from "./MasonryGrid.block";
import { Img } from "~/components/image";
import { objectPosition } from "~/utils/builder/enums";

const MasonryGrid: Component<MasonryGridFields> = (props) => {
  const layoutOptions: Record<string, string> = {
    Left: "first:col-span-2 first:row-span-2",
    Right: "last:col-span-2 last:row-span-2",
  };
  const filter = (y: string | undefined) => {
    switch (y) {
      case "None":
        return "";
      case "Darken":
        return "backdrop-brightness-75";
      case "Lighten":
        return "backdrop-brightness-125";
      default:
        return "";
    }
  };
  return (
    <div id={props.sectionID ?? undefined}>
      <Show when={props.sectionTitle}>
        <h2 class="text-center text:xl sm:text-2xl font-bold px-5 md:pb-10">
          {props.sectionTitle}
        </h2>
      </Show>
      <Show when={props.imgBlock}>
        <div
          class={`grid grid-cols-2 ${
            props.imgBlock!.length > 2 ? "md:grid-cols-3" : "md:grid-cols-2"
          } gap-5 grid-flow-dense md:grid-flow-col`}
        >
          <For each={props.imgBlock}>
            {(item) => (
              <Dynamic
                component={item.linkImage && item.imgUrl ? A : "div"}
                href={item.linkImage && item.imgUrl ? item.imgUrl : undefined}
                target={item.linkImage && item.newTab ? "_blank" : undefined}
                class={`${
                  props.imgBlock!.length > 2 ? layoutOptions[props.layout!] : ""
                } aspect-3/2 relative h-full w-full`}
              >
                <Img
                  src={item.image}
                  alt={item.imageAlt ?? ""}
                  class="w-full h-full object-cover"
                  layout="fullWidth"
                  classList={{
                    [objectPosition[item.bgPosition as string]]:
                      !!item.bgPosition,
                    "-scale-x-100": item.flipHorizontal,
                    "-scale-y-100": item.flipVertical,
                  }}
                />
                <div
                  class={`absolute w-full h-full bottom-0 flex flex-col md:gap-1 justify-end p-2 md:p-10 font-bold ${filter(
                    item.filterBg
                  )} ${
                    item.textColor === "White" ? "text-white" : "text-black"
                  }`}
                >
                  <div
                    class={`flex ${
                      item.subHeadingPos === "Top"
                        ? "flex-col"
                        : "flex-col-reverse"
                    }`}
                  >
                    <p class="text-xs md:text-sm">{item.subHeading}</p>
                    <p class="text-base md:text-2xl">{item.heading}</p>
                  </div>
                </div>
              </Dynamic>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};

export default MasonryGrid;
