import ImageWithContent from "./ImageWithContent";

const inputs = [
  {
    name: "id",
    friendlyName: "ID",
    type: "string",
    helperText:
      "Add an ID if this section will be linked to within a page. ID should be hyphenated or 'camelCase', do not use spaces",
  },
  {
    name: "image",
    type: "file",
    helperText: "Please upload high resolution image, ideal size XXX x XXX",
    allowedFileTypes: ["png", "jpeg", "jpg", "svg"],
  },
  {
    name: "altText",
    friendlyName: "Image Alt Text",
    type: "string",
    helperText: "Description of image for accessibility and SEO",
  },
  {
    name: "layout",
    type: "string",
    enum: ["Left", "Right", "Full"],
    required: true,
    defaultValue: "Left",
  },
] as const;

export type ImageWithContentFields = BuilderFields<
  GenerateItems<typeof inputs>
>;

const ImageWithContentBlock = {
  component: ImageWithContent,
  name: "ImageWithContent",
  friendlyName: "Image With Content",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fcc86d8c6a4b34005aa0cca45672285b8",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fb3e28e582bef47548e6a3a0043e7d6f6",
  canHaveChildren: true,
  defaultChildren: [
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "HeaderWithContent",
        options: {
          isColumn: true,
        },
      },
    },
  ],
  inputs,
};

export default ImageWithContentBlock;
