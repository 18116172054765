import { FlowComponent } from "solid-js";
import { Dynamic } from "solid-js/web";
import { CTAFields } from "./CTA.block";
import { iconPath } from "~/utils/icons";
import { contentWidth } from "~/utils/builder/enums";
import { Img } from "~/components/image";

const CTA: FlowComponent<
  CTAFields & { class?: string; contentWidth?: string }
> = (props) => {
  return (
    <div
      class="flex flex-col items-center text-center"
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <div class="w-10 mb-3">
        <img
          src={iconPath[props.icon as string]}
          width={40}
          height={40}
          layout="constrained"
          alt=""
        />
      </div>
      <Dynamic
        component={props.headingLevel ?? "h3"}
        class="text-xl font-bold mb-3"
      >
        {props.title}
      </Dynamic>
      <div
        class="mb-4 text-base"
        innerHTML={props.description}
        classList={
          props.contentWidth
            ? { [`${contentWidth[props.contentWidth]}`]: !!props.contentWidth }
            : undefined
        }
      />
      {props.children}
    </div>
  );
};

export default CTA;
