import MasonryGrid from "./MasonryGrid";
import placeholder from "~/assets/placeholder.png";
import { enumBgPosition } from "~/utils/builder/enums";

const inputs = [
  {
    name: "sectionTitle",
    friendlyName: "Section Title",
    type: "string",
    helperText: "Optional section title",
  },
  {
    name: "imgBlock",
    friendlyName: "Image Block",
    type: "list",
    //@ts-ignore
    onChange: (options, parent) => {
      if (options.get("imgBlock").length > 3) {
        options.set("imgBlock", options.get("imgBlock").slice(0, 3));
        alert("maximum items is 3, delete items to continue");
      }
    },
    defaultValue: [
      {
        image: placeholder,
        heading: "Heading",
        subHeading: "Sub Heading",
        subHeadingPos: "Top",
        textColor: "Black",
      },
      {
        image: placeholder,
        heading: "Heading",
        subHeading: "Sub Heading",
        subHeadingPos: "Top",
        textColor: "Black",
      },
      {
        image: placeholder,
        heading: "Heading",
        subHeading: "Sub Heading",
        subHeadingPos: "Top",
        textColor: "Black",
      },
    ],
    helperText: "Add up to 3 images, for a larger grid, stack components",
    subFields: [
      {
        name: "image",
        friendlyName: "Upload an Image",
        type: "file",
        allowedFileTypes: ["svg", "png", "jpeg", "jpg"],
        required: true,
      },
      {
        name: "imageAlt",
        friendlyName: "Image Alt Text",
        type: "string",
        helperText: "Description of image for accessibility and SEO",
      },
      {
        name: "bgPosition",
        friendlyName: "Background Position",
        type: "string",
        enum: enumBgPosition,
        defaultValue: "Center",
        helperText:
          "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio.",
      },
      {
        name: "flipHorizontal",
        friendlyName: "Flip Image Horizontal",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "flipVertical",
        friendlyName: "Flip Image Vertical",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "filterBg",
        friendlyName: "Darken / Lighten Image?",
        type: "string",
        enum: ["None", "Darken", "Lighten"],
        helperText:
          "You can darken or lighten the banner so text is more legible",
      },
      {
        name: "heading",
        friendlyName: "Heading Text",
        helperText: "This text appears largest",
        type: "string",
      },
      {
        name: "subHeading",
        friendlyName: "Subheading Text",
        type: "string",
        helperText: "This text appears smaller, and above or below the heading",
      },
      {
        name: "subHeadingPos",
        friendlyName: "Subheading Position",
        type: "string",
        enum: ["Top", "Bottom"],
        // defaultValue: "Top",
        helperText: "Position the subheading above or below the heading",
      },
      {
        name: "textColor",
        friendlyName: "Text Color",
        type: "string",
        enum: ["White", "Black"],
        // defaultValue: "White",
      },
      {
        name: "linkImage",
        friendlyName: "Add link to image",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "imgUrl",
        friendlyName: "URL",
        helperText:
          "For internal linking (ie. Shop page) use relative linking. '/shop?Collection=Lavo'. For external links 'https://www...'",
        type: "string",
        showIf: "options.get('linkImage') === true",
      },
      {
        name: "newTab",
        friendlyName: "Open in new tab?",
        type: "boolean",
        defaultValue: false,
        showIf: "options.get('linkImage') === true",
      },
    ],
  },
  {
    name: "layout",
    friendlyName: "Reverse Layout",
    type: "string",
    enum: ["Left", "Right"],
    defaultValue: "Left",
    helperText:
      "In 3-Image grid, make the left or right image larger (top/bottom on mobile)",
  },
  {
    name: "sectionID",
    friendlyName: "Section ID",
    type: "string",
    helperText: "Optional - use for anchor links",
  },
] as const;

export type MasonryGridFields = BuilderFields<GenerateItems<typeof inputs>>;

const MasonryGridBlock = {
  component: MasonryGrid,
  name: "MasonryGrid",
  friendlyName: "Masonry Grid",
  section: "Layout Components",
  inputs,
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F02b2b63de7d441bd853004a71bc91b1e",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F8c7dfae01a19416293a631fece83b5cf",
};

export default MasonryGridBlock;
