import Form from "./Form";

const inputs = [
  {
    name: "header",
    friendlyName: "Heading Text",
    type: "string",
    required: true,
    helperText: "Enter a heading for this form",
  },
  {
    name: "bodyContent",
    friendlyName: "Body Text Content",
    type: "longText",
  },
  {
    name: "id",
    friendlyName: "ID",
    type: "string",
    helperText: "Add an ID if this section will be linked to within a page. ID should be hyphenated or 'camelCase', do not use spaces"
  },
  {
    name: "input",
    friendlyName: "Input Fields",
    type: "list",
    required: true,
    subFields: [
      {
        name: "type",
        friendlyName: "Type of Input",
        type: "string",
        enum: ["Text", "TextArea"],
        required: true,
      },
      {
        name: "fieldName",
        friendlyName: "Display Name",
        type: "string",
        helperText: "This will display above the input field",
        required: true,
      },
      {
        name: "label",
        type: "string",
        required: true,
        helperText:
          '* This should be one word or "camelCase" to describe the input type eg. phoneNumber',
      },
      {
        name: "placeholder",
        friendlyName: "Placeholder Text",
        type: "string",
        helperText:
          "This text will appear inside the input field e.g email@domain.com",
      },
      {
        name: "instruction",
        friendlyName: "Instructional Text",
        type: "string",
        helperText:
          "Additional instructions to assist the user with this field",
      },
      {
        name: "isRequired",
        friendlyName: "Required Input?",
        type: "boolean",
        helperText:
          "If enabled, form cannot be submitted unless this field is filled",
        required: true,
        default: false,
      },
      {
        name: "validationType",
        friendlyName: "Validation Required?",
        type: "string",
        enum: ["None", "Phone", "Email",],
        defaultValue: "None",
        required: true,
      },
      {
        name: "maxLength",
        friendlyName: "Max Length?",
        type: "number",
      }
    ],
  },
  {
    name: "buttonText",
    friendlyName: "Button Text",
    type: "string",
    required: true,
    helperText: "eg. Submit, Sign Up..",
  },
] as const;

export type FormFields = BuilderFields<GenerateItems<typeof inputs>>;

const FormBlock = {
  component: Form,
  name: "Form",
  section: "Interactive Components",
  screenshot:"https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F97ae99d412be4f3d9632ea714035ea4d",
  image: 'https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F01388777730a44e081365a3807c6ec66',
  inputs,
};

export default FormBlock;
