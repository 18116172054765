import { Component, Accessor } from "solid-js";
import Nav from "./Nav";
import { HeaderFields } from "./Header.block";

const Header: Component<HeaderFields & { visible: Accessor<boolean> }> = (
  props
) => {
  return <Nav show={props.visible} structure={props.navigation} />;
};

export default Header;
