import CardFeature from "./CardFeature";
import {
  enumFilter,
  enumAspectRatio,
  enumBgPosition,
} from "~/utils/builder/enums";

const inputs = [
  {
    name: "upperTitle",
    friendlyName: "Upper Title",
    type: "string",
  },
  {
    name: "title",
    type: "string",
    required: true,
    defaultValue: "Title Text",
  },
  {
    name: "image",
    type: "file",
    required: true,
    allowedFileTypes: ["png", "svg", "jpg", "jpeg"],
  },
  {
    name: "imageAlt",
    type: "string",
    defaultValue: "",
    friendlyName: "Image Alt Text"
  },
  {
    name: "aspectRatioLg",
    friendlyName: "Aspect Ratio - Large",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "4:3",
    required: true,
    helpertext: "This aspect ratio will show on tablet and desktop",
    onChange: (options: any, parent: any) => {
      options.set("aspectRatioSm", options.get("aspectRatioLg"));
    },
  },
  {
    name: "aspectRatioSm",
    friendlyName: "Aspect Ratio - Small",
    type: "string",
    enum: enumAspectRatio,
    defaultValue: "4:3",
    required: true,
    helpertext: "This aspect ratio will show on mobile",
  },
  {
    name: "bgPosition",
    friendlyName: "Background Position",
    type: "string",
    enum: enumBgPosition,
    defaultValue: "Center",
    required: true,
    helperText:
      "Please select an area to focus - note that edges may be cut off depending on the selected aspect ratio / different screen sizes. For best results with little cutoff, image should already be sized at the selected aspect ratio.",
  },
  {
    name: "bgFilter",
    friendlyName: "Darken / Lighten Image?",
    type: "string",
    enum: enumFilter,
    defaultValue: "None",
    required: true,
    helperText:
      "Darken or Lighten the image so that the text is more legible - it will darken further on hover",
  },
  {
    name: "hasUpperImage",
    friendlyName: "Show Image Above Header?",
    type: "boolean",
    defaultValue: false,
    required: true,
  },
  {
    name: "upperImage",
    friendlyName: "Upper Image",
    type: "file",
    allowedFileTypes: ["jpg", "jpeg", "png", "svg"],
    showIf: "options.get('hasUpperImage') === true",
  },
  {
    name: "upperImageAlt",
    friendlyName: "Upper Image Alt Text",
    type: "string",
    showIf: "options.get('hasUpperImage') === true",
  },
  {
    name: "titleSize",
    friendlyName: "Title Size",
    type: "string",
    required: true,
    enum: ["Small", "Medium", "Large"],
  },
  {
    name: "url",
    type: "url",
  },
  {
    name: "ratio",
    friendlyName: "Image Ratio",
    type: "string",
    defaultValue: "1/1",
    enum: ["1/1", "16/9", "4/3"],
  },
  {
    name: "textColour",
    friendlyName: "Text Colour",
    type: "string",
    enum: ["Black", "White"],
    defaultValue: "White",
  },
  {
    name: "copy",
    type: "string",
  },
  {
    name: "buttons",
    type: "list",
    subFields: [
      {
        name: "label",
        type: "string",
        required: true,
      },
      {
        name: "icon",
        type: "string",
        enum: ["Arrow", "Play"],
        defaultValue: "Arrow",
      },
      {
        name: "url",
        type: "string",
        required: true,
      },
    ],
    defaultValue: [
      {
        label: "Label",
        url: "/",
      },
    ],
  },
  {
    name: "buttonPosition",
    type: "string",
    enum: ["Bottom", "Center"],
    defaultValue: "Bottom",
    required: true,
  },
] as const;

export const CardFeatureBlock = {
  component: CardFeature,
  name: "CardFeature",
  friendlyName: "Card Feature",
  section: "Content Components",
  inputs,
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F46ca0a9ce3cc45b5ae335a8ba21ee529",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F10edd5ee38504e10a0c6959292c1741c",
};

export type CardFeatureFields = BuilderFields<GenerateItems<typeof inputs>>;

export default CardFeatureBlock;
