import Breadcrumb from "./Breadcrumb";

const inputs = [
  {
    name: "breadcrumbs",
    type: "list",
    subFields: [
      {
        name: "pageTitle",
        type: "string",
      },
      {
        name: "url",
        type: "string",
      },
    ],
    defaultValue: [{
      pageTitle: 'Home',
      url: '/'
    }]
  },
] as const;

export type BreadcrumbFields = BuilderFields<GenerateItems<typeof inputs>>;

const BreadcrumbBlock = {
  component: Breadcrumb,
  name: "Breadcrumb",
  section: "Content Components",
  noWrap: true,
  inputs,
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F675951d510a242ba84c5e7cff2d22801",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F8e1a7fe6c3944a64a486a3f8699427bd",
};

export default BreadcrumbBlock;
