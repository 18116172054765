import LongQuote from "./LongQuote";
import femaleFigure from "~/assets/femaleFigure.png";

const inputs = [
  {
    name: "quotationContent",
    friendlyName: "Quote Body Content",
    type: "longText",
    required: "true",
  },
  {
    name: "quotee",
    friendlyName: "Quotee Name",
    type: "string",
    defaultValue: "FirstName LastName",
    helperText: "eg. Tony Gareri, CEO",
  },
  {
    name: "isQuotationMark",
    friendlyName: "Quotation Marks?",
    type: "boolean",
    defaultValue: true,
  },
  {
    name: "image",
    friendlyName: "Upload Photo",
    type: "file",
    allowedFileTypes: ["svg", "png", "jpg", "jpeg"],
    defaultValue: femaleFigure,
    helperText:
      "Use square (min 500px).png file with transparent background, high resolution, grayscale is applied automatically",
  },
  {
    name: "altText",
    friendlyName: "Image Description",
    type: "longText",
    helperText:
      "A short sentence to describe the photo (eg. Photo of Tony Gareri) - this is helpful for accessibility and SEO",
  },
  {
    name: "layout",
    friendlyName: "Layout",
    type: "string",
    enum: ["Right", "Left"],
    defaultValue: "Right",
  },
  {
    name: "fullWidth",
    friendlyName: "Full Width",
    type: "boolean",
    defaultValue: false,
    helperText: "Enabling will remove the default x-padding, you may adjust the padding manually in Builder under the Style tab"
  }
] as const;

export type LongQuoteFields = BuilderFields<GenerateItems<typeof inputs>>;

const LongQuoteBlock = {
  component: LongQuote,
  name: "LongQuote",
  friendlyName: "Long Quote",
  section: "Content Components",
  screenshot:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F3514683003dd4ad4bdde3a41d4952de9",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fa45aa4eb49d0419fbf30c765ad98d744",
  inputs,
};

export default LongQuoteBlock;
