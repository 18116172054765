import { FlowComponent, For } from "solid-js";
import CTAAction from "./CTAAction";
import { CallToActionFields } from "./CallToAction.block";

const CallToAction: FlowComponent<CallToActionFields> = (props) => (
  <section>
    <div class="flex justify-center max-md:gap-10 md:divide-x flex-col md:flex-row sm:child:px-3 md:child:px-5 lg:child:px-10">
      <For each={props.list}>
        {(item: any) => (
          <div
            classList={{
              "md:w-full": props.list.length === 1,
              "md:w-1/2": props.list.length === 2,
              "md:w-1/3": props.list.length === 3,
            }}
          >
            <CTAAction {...item} />
          </div>
        )}
      </For>
    </div>
  </section>
);

export default CallToAction;
